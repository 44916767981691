<template>
  <nav class="navbar sticky-toolbar sticky-top navbar-light bg-light">
    <span class="navbar-brand">
      <span>{{ planningConfig.season.label }}</span>
      {{ planningConfig.product.value.toUpperCase().replace(/_/g, " ") }}
    </span>
    <div class="container-nav-btn-left">
      <button class="stepNextWizardBtn" :class="{ disabled: isSuggested }" @click="handleBackClick()">Back</button>
    </div>
    <ul class="nav nav-pills justify-content-center">
      <li class="nav-item">
        <RouterLink to="/planning/suggested" class="nav-link wizardBtn noClick" active-class="active">
          <b>Step 1</b>
          <br />
          <small class="stepInfo">Material Level Planning</small>
        </RouterLink>
      </li>
      <li class="nav-item">
        <span class="nav-link text-muted" style="font-size: x-large"> > </span>
      </li>
      <li class="nav-item">
        <RouterLink to="/planning/edit" class="nav-link wizardBtn noClick" active-class="active">
          <b>Step 2</b>
          <br />
          <small class="stepInfo">SKU Level Planning</small>
        </RouterLink>
      </li>
    </ul>
    <div class="container-nav-btn-right">
      <button class="stepBackWizardBtn" :class="{ disabled: isEdit }" @click="handleNextClick()">Next</button>
    </div>
    <div>
      <div class="save-container">
        <div class="save-progress">
          <button class="btn btn-sm btn-outline-primary" @click="handleSaveButton">
            Save Progress
            <Tooltip
              text="Click to save your work. Next time you log in your saved changes will appear"
              v-if="$route.path.includes('suggested')"
            ></Tooltip>
            <Tooltip
              v-else
              text="Click 'Save Progress' to save planned units at the SKU Level. This will save your planned order by SKU.  When you return to continue planning your order, use the drop down below to select 'Last Saved Data'. This will bring up your planned orders. You must save your planned order prior to clicking 'Save to Orders'"
            ></Tooltip>
          </button>
          <b style="color: #c8102d" v-if="this.unsavedChanges()">You have unsaved changes!</b>
        </div>
        <div class="save-progress">
          <button class="btn btn-sm btn-outline-primary" @click="handleSaveOrder" v-if="checkOnSKUStep">
            Save to Orders
            <Tooltip :text="saveToOrdersTooltip"></Tooltip>
          </button>
          <b style="color: #c8102d" v-if="$route.path.includes('edit')"> Save progress prior to saving to orders! </b>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import Tooltip from "@/components/common/Tooltip";
import { API, graphqlOperation } from "aws-amplify";
import { saveUpcOrders } from "@/graphql/mutations";

export default {
  components: {
    Tooltip,
  },
  computed: {
    ...mapGetters(["planningConfig", "user", "currentSeasonMutation", "product"]),
    isSuggested() {
      return this.$route.path.includes("suggested");
    },
    isUpload() {
      return this.$route.path.includes("uploadtobb");
    },
    isEdit() {
      return this.$route.path.includes("edit");
    },
    checkOnSKUStep() {
      return this.$route.path.includes("edit");
    },
    unsavedChanges() {
      return () => {
        this.recompute = this.$materialAllocation.changeEvent;
        let ret = false;
        if (this.$route.path.includes("suggested")) {
          ret = this.$materialAllocation.isDirty;
        }
        if (this.$route.path.includes("edit")) {
          ret = this.$materialAllocation.stepTwoDirty;
        }
        return ret;
      };
    },
  },
  data() {
    return {
      list: ["Retailer", "Product", "Reset"],
      value: "change",
      visible: false,
      recompute: 0,
      saveToOrdersTooltip: `Click this to save your planned order below to the "Upload to Bauer Biz" step`,
    };
  },
  methods: {
    handleSaveButton(doneFromWizard) {
      this.$store.commit("setUnsavedChangesFlag", false);

      if (doneFromWizard === true) {
        this.$store.commit("setWasLastSaveFromSaveButton", false);
      } else {
        this.$store.commit("setWasLastSaveFromSaveButton", true);
      }
      let tabToSave = document.URL.split("/").pop();
      if (tabToSave === "suggested") {
        this.$events.$emit("saveSuggestedMonthlyBookings");
      } else if (tabToSave === "edit") {
        this.$root.$emit("saveBookings");
      }
    },

    async handleSaveOrder() {
      try {
        //AE-4 : Transfer orders from upc saved table to upc orders
        const apiParams = {
          sold_to_id: this.planningConfig.retailer.soldto_id,
          season: this.currentSeasonMutation,
          category: this.product.id,
        };
        const { data } = await API.graphql(graphqlOperation(saveUpcOrders, { saveUpcOrders: apiParams }));
        this.$overlay.show("Saved to orders successfully for id " + data.saveUpcOrders.sold_to_id);
        setTimeout(this.$overlay.hide, 2000);
      } catch (err) {
        this.$overlay.show("Error", err);
        setTimeout(this.$overlay.hide, 2000);
      }
    },
    handleNextClick() {
      if (this.$route.path.includes("edit")) {
        this.$router.push("/planning/uploadtobb");
      }
      if (this.$route.path.includes("suggested")) {
        this.$router.push("/planning/edit");
      }
    },
    handleBackClick() {
      if (this.$route.path.includes("edit")) {
        this.$router.push("/planning/suggested");
      } else if (this.$route.path.includes("uploadtobb")) {
        this.$router.push("/planning/edit");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.gg-info-hover {
  color: #fff;
}
.stepInfo {
  font-size: 0.7em;
  font-weight: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}
.wizardBtn {
  text-align: center;
  line-height: 1em !important;
}
.stepNextWizardBtn {
  color: #ffffff;
  background-color: #007bff;
  width: 5em;
  border-radius: 2px !important;
  border: none;
}
.stepBackWizardBtn {
  color: #ffffff;
  background-color: #007bff;
  width: 5em;
  border-radius: 2px !important;
  border: none;
}
.container-nav-btn-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -12em;
}
.container-nav-btn-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -12em;
}
.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  pointer-events: none;
}
.noClick {
  cursor: not-allowed;
  pointer-events: none;
}
.save-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.save-container {
  display: flex;
  align-items: center;
  column-gap: 70px;
}

@media screen and (max-width: 1600px) {
  .container-nav-btn-left {
    margin-right: 0px !important;
  }
  .container-nav-btn-right {
    margin-left: 0px !important;
  }
}

.note-save-orders {
  position: absolute;
  top: 50px;
  right: 10px;
  font-size: 14px;
  color: red;
}
</style>
