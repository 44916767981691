<template>
    <div class="planning__header">
        <div class="planning__header-container row">
            <div class="planning__header-left col-6">
                <div class="planning__header-left-1">
                    <div class="planning__header-copy">
                        <h5><span>Sold to:</span></h5>
                    </div>
                    <div class="planning__header-copy">
                        <h5><span>Sold to ID:</span></h5>
                    </div>
                    <div class="planning__header-copy">
                        <h5><span>Buying Group:</span></h5>
                    </div>
                </div>
                <div class="planning__header-left-2">
                    <div class="planning__header-copy">
                        <h5>{{ retailer.soldto }}</h5>
                    </div>
                    <div class="planning__header-copy">
                        <h5>{{ retailer.soldto_id }}</h5>
                    </div>
                    <div class="planning__header-copy">
                        <h5>{{ retailer.buying_group_code }}</h5>
                    </div>
                </div>
            </div>
            <div class="planning__header-right col-6">
                <div class="planning__header-right-1">
                    <div class="planning__header-copy">
                        <h5><span>Country:</span></h5>
                    </div>
                    <div class="planning__header-copy">
                        <h5><span>Sales Rep:</span></h5>
                    </div>
                    <div class="planning__header-copy">
                        <h5><span>Region:</span></h5>
                    </div>
                </div>
                <div class="planning__header-right-2">
                    <div class="planning__header-copy">
                        <h5>{{ retailer.sorg }}</h5>
                    </div>
                    <div class="planning__header-copy">
                        <h5>{{ retailer.salesrep_current }}</h5>
                    </div>
                    <div class="planning__header-copy">
                        <h5>{{ retailer.region }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['retailer']),
    },
};
</script>
