/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteRetailer = /* GraphQL */ `
  mutation DeleteRetailer($shipto_id: Int!) {
    deleteRetailer(shipto_id: $shipto_id) {
      shipto_id
      shipto
      soldto_id
      soldto
      sorg
      country
      buying_group_code
      buying_group
      salesrep_id
      salesrep_current
      shipto_region
      parent_id
      parent_name
      division
      bauerhockey_class
      account_type
      market
      region
      segmentation
      sell_thru_name
      sell_thru_identifier
      retailer_type
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $soldto_id: Int
    $season: String
    $modified_by: String
    $monthIndex: Int
    $material_numbers: [String]
  ) {
    deleteOrder(
      soldto_id: $soldto_id
      season: $season
      modified_by: $modified_by
      monthIndex: $monthIndex
      material_numbers: $material_numbers
    )
  }
`;
export const deleteSavedUpcOrder = /* GraphQL */ `
  mutation DeleteSavedUpcOrder(
    $soldto_id: Int
    $season: String
    $modified_by: String
    $category: String
  ) {
    deleteSavedUpcOrder(
      soldto_id: $soldto_id
      season: $season
      modified_by: $modified_by
      category: $category
    )
  }
`;
export const createRetailer = /* GraphQL */ `
  mutation CreateRetailer($createRetailerInput: CreateRetailerInput!) {
    createRetailer(createRetailerInput: $createRetailerInput) {
      shipto_id
      shipto
      soldto_id
      soldto
      sorg
      country
      buying_group_code
      buying_group
      salesrep_id
      salesrep_current
      shipto_region
      parent_id
      parent_name
      division
      bauerhockey_class
      account_type
      market
      region
      segmentation
      sell_thru_name
      sell_thru_identifier
      retailer_type
      __typename
    }
  }
`;
export const updateRetailer = /* GraphQL */ `
  mutation UpdateRetailer($updateRetailerInput: UpdateRetailerInput!) {
    updateRetailer(updateRetailerInput: $updateRetailerInput) {
      shipto_id
      shipto
      soldto_id
      soldto
      sorg
      country
      buying_group_code
      buying_group
      salesrep_id
      salesrep_current
      shipto_region
      parent_id
      parent_name
      division
      bauerhockey_class
      account_type
      market
      region
      segmentation
      sell_thru_name
      sell_thru_identifier
      retailer_type
      __typename
    }
  }
`;
export const deleteUpc = /* GraphQL */ `
  mutation DeleteUpc($upc_number: Int!) {
    deleteUpc(upc_number: $upc_number) {
      upc_number
      material_number
      size_dimension
      dim1
      dim2
      material_description
      main_description
      group_desc
      segment
      pgs
      family
      gender
      begin_date
      end_date
      begin_season
      end_season
      launch_date
      smu_group
      prod_type
      glp_status
      ph_number
      flex
      pattern
      hand
      model
      __typename
    }
  }
`;
export const createUpc = /* GraphQL */ `
  mutation CreateUpc($createUpcInput: CreateUpcInput!) {
    createUpc(createUpcInput: $createUpcInput) {
      upc_number
      material_number
      size_dimension
      dim1
      dim2
      material_description
      main_description
      group_desc
      segment
      pgs
      family
      gender
      begin_date
      end_date
      begin_season
      end_season
      launch_date
      smu_group
      prod_type
      glp_status
      ph_number
      flex
      pattern
      hand
      model
      __typename
    }
  }
`;
export const updateUpc = /* GraphQL */ `
  mutation UpdateUpc($updateUpcInput: UpdateUpcInput!) {
    updateUpc(updateUpcInput: $updateUpcInput) {
      upc_number
      material_number
      size_dimension
      dim1
      dim2
      material_description
      main_description
      group_desc
      segment
      pgs
      family
      gender
      begin_date
      end_date
      begin_season
      end_season
      launch_date
      smu_group
      prod_type
      glp_status
      ph_number
      flex
      pattern
      hand
      model
      __typename
    }
  }
`;
export const addAdjustedBookings = /* GraphQL */ `
  mutation AddAdjustedBookings($adjustedBooking: AdjustedBooking!) {
    addAdjustedBookings(adjustedBooking: $adjustedBooking) {
      soldto_id
      upc
      material
      units
      modified_by
      last_modified
      season
      sub_season
      __typename
    }
  }
`;
export const updateMonthlyTotals = /* GraphQL */ `
  mutation UpdateMonthlyTotals($monthlyTotalBookings: MonthlyTotalBookings!) {
    updateMonthlyTotals(monthlyTotalBookings: $monthlyTotalBookings) {
      soldto_id
      category
      season
      modified_by
      last_modified_on
      booking_date
      units
      segment
      type
      split
      __typename
    }
  }
`;
export const updatePercentages = /* GraphQL */ `
  mutation UpdatePercentages($updatePercentages: UpdatePercentages!) {
    updatePercentages(updatePercentages: $updatePercentages) {
      soldto_id
      category
      season
      modified_by
      last_modified_on
      segments
      weight
      __typename
    }
  }
`;
export const updateMaterialSplit = /* GraphQL */ `
  mutation UpdateMaterialSplit($updateMaterialSplit: UpdateMaterialSplit!) {
    updateMaterialSplit(updateMaterialSplit: $updateMaterialSplit) {
      soldto_id
      category
      season
      modified_by
      last_modified_on
      material_split
      split_id
      __typename
    }
  }
`;
export const addFreezeMaterialData = /* GraphQL */ `
  mutation AddFreezeMaterialData(
    $addFreezeMaterialData: UpdateFreezeMaterialData
  ) {
    addFreezeMaterialData(addFreezeMaterialData: $addFreezeMaterialData) {
      soldto_id
      category
      season
      modified_by
      last_modified_on
      material
      booking_date
      quantity
      __typename
    }
  }
`;
export const saveUpcData = /* GraphQL */ `
  mutation SaveUpcData($saveUpcData: [SavingUpcData]) {
    saveUpcData(saveUpcData: $saveUpcData) {
      soldto_id
      category
      season
      modified_by
      last_modified_on
      upc
      booking_date
      quantity
      material_number
      __typename
    }
  }
`;
export const saveUpcOrders = /* GraphQL */ `
  mutation SaveUpcOrders($saveUpcOrders: SavingUpcOrder) {
    saveUpcOrders(saveUpcOrders: $saveUpcOrders) {
      sold_to_id
      __typename
    }
  }
`;
export const freezeUpcData = /* GraphQL */ `
  mutation FreezeUpcData($saveUpcData: [SavingUpcData]) {
    freezeUpcData(saveUpcData: $saveUpcData) {
      soldto_id
      category
      season
      modified_by
      last_modified_on
      upc
      booking_date
      quantity
      material_number
      __typename
    }
  }
`;
export const saveFinalOrderDataBbResponse = /* GraphQL */ `
  mutation SaveFinalOrderDataBbResponse(
    $saveFinalOrderDataBbResponse: [SavingFinalOrderDataBbResponse]
  ) {
    saveFinalOrderDataBbResponse(
      saveFinalOrderDataBbResponse: $saveFinalOrderDataBbResponse
    ) {
      order_id
      soldto_id
      season
      bucket
      month
      po_number
      confirmation_id
      response_code
      response_message
      modified_by
      last_modified_on
      sorg
      currency_type
      order_qty
      __typename
    }
  }
`;
export const saveCustomMaterialAllocation = /* GraphQL */ `
  mutation SaveCustomMaterialAllocation(
    $saveCustomMaterialAllocation: SavingCustomMaterialAllocation!
  ) {
    saveCustomMaterialAllocation(
      saveCustomMaterialAllocation: $saveCustomMaterialAllocation
    ) {
      id
      soldto_id
      material_number
      amount
      month
      month_index
      segment
      type
      units_entered
      category
      year
      active
      modified_by
      __typename
    }
  }
`;
export const deleteOldCustomMaterialAllocations = /* GraphQL */ `
  mutation DeleteOldCustomMaterialAllocations(
    $saveCustomMaterialAllocation: SavingCustomMaterialAllocation!
  ) {
    deleteOldCustomMaterialAllocations(
      saveCustomMaterialAllocation: $saveCustomMaterialAllocation
    )
  }
`;
export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
