<template>
  <div class="header row_header">
    <div class="header__left col-6-special">
      <img src="https://bauer.a.bigcontent.io/v1/static/logo-bauer-blue" alt="Bauer Logo" />
      <p v-if="$route.path != '/portal' && !$route.path.includes('reporting')">Assortment Planning Tool</p>
    </div>
    <div class="header__right col-6-special" v-if="$route.path != '/portal'">
      <div
        class="header__action"
        v-if="$route.path != '/portal' && !$route.path.includes('setup') && !$route.path.includes('reporting')"
      >
        <button @click="handleChangeSettingsButton">
          Change Settings<Tooltip text="Returns to tool selection"></Tooltip>
        </button>
      </div>
      <div class="header__action" v-if="$route.path != '/portal' && !$route.path.includes('planning')">
        <router-link to="/planning/setup" active-class="active">
          <button>
            Planning<Tooltip
              text="In the reporting screen?  Select Planning to get back into the setup Tab"
            ></Tooltip>
          </button>
        </router-link>
      </div>
      <div class="header__action" v-if="$route.path != '/portal' && !$route.path.includes('reporting')">
        <router-link to="/reporting" active-class="active">
          <button>Reporting<Tooltip text="Click to go to Reports and Dashboards"></Tooltip></button>
        </router-link>
      </div>
      <div class="header__action mr-0">
        <button @click="signOut">Logout<Tooltip text="Reminder to save progress before logging out"></Tooltip></button>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapGetters } from "vuex";
import Tooltip from "@/components/common/Tooltip";

export default {
  components: {
    Tooltip,
  },
  data() {
    return {
      list: ["Retailer", "Product", "Reset"],
      value: "change",
      visible: false,
    };
  },
  computed: {
    ...mapGetters(["name"]),
  },
  methods: {
    signOut() {
      Auth.signOut()
        .then(() => {
          this.$store.commit("logout");
          this.$store.commit("setShowMaterialData", false);
          this.$store.commit("setSellInDataIdentifier", false);
          this.navigateToAuth();
        })
        .catch((err) => console.log(err));
    },
    navigateToAuth() {
      this.$router.push("/").catch((error) => {
        console.info(error.message);
      });
    },
    toggle() {
      this.visible = !this.visible;
    },
    select(option) {
      this.$store.commit("setSellInDataIdentifier", false);
      this.$store.commit("resetSuggestedBooking");
      this.$store.commit("setShowMaterialData", false);
      if (option === "Retailer" || option === "Reset") {
        this.$router.push("/user");
      } else if (option === "Product") {
        this.$router.push("/products");
      }
    },

    handleChangeSettingsButton() {
      if (this.$store.state.unsavedChangesFlag) {
        this.$toasted.show("You have unsaved changes. Do you want save them before proceeding?", {
          action: [
            {
              text: "No",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
                this.$router.push("../portal");
                this.$events.$emit("changeSettings");
                this.$store.commit("setUnsavedChangesFlag", false);
              },
            },
            {
              text: "Yes",
              onClick: (_e, toastObject) => {
                toastObject.goAway(0);
                let tabToSave = document.URL.split("/").pop();
                if (tabToSave === "suggested") {
                  this.$events.$emit("saveSuggestedMonthlyBookings", { gotosettings: true });
                } else if (tabToSave === "edit") {
                  this.$root.$emit("saveBookings", { gotosettings: true });
                }
                this.$events.$emit("changeSettings");
                this.$store.commit("setUnsavedChangesFlag", false);
              },
            },
          ],
          position: "top-center",
          duration: null,
          className: ["changeSettings"],
        });
      } else {
        this.$router.push("../portal");
        this.$events.$emit("changeSettings");
        this.$store.commit("setUnsavedChangesFlag", false);
        this.$store.commit("setSellThruData", {});
      }
      this.$store.commit("setCustomSavedAllocationValues", {});
      this.$store.commit("setCalculatedAllocationValues", {});
      this.$store.commit("setUserChangedFlag", false);
      this.$store.commit("setSellThruData", {});
      this.$store.commit("setRegionData", {});
      this.$store.commit("setMarketData", {});
      this.$store.commit("setCountryData", {});
    },
  },
};
</script>
<style>
.changeSettings {
  margin-top: 30vh !important;
  color: white !important;
  font-size: large !important;
  border: 2px solid #f8f9fa !important;
  background-color: #009acd !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4) !important; /* X-offset, Y-offset, blur, color */
  /* other styles as needed */
}
.changeSettings a {
  color: white !important;
  border: 1px solid #f8f9fa !important;
}

.changeSettings a:first-of-type {
  margin-right: 1px !important;
}

.attention_note {
  background-color: yellow;
  height: 30px;
  margin-top: 25px;
  padding-top: 5px;
  p {
    width: fit-content;
  }
}

.row_header {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
}

.col-6-special {
  flex: 1;
  max-width: 50%;
  position: relative;
  width: 100%;
}
</style>
