<template>
    <div id="app">
        <RouterView />
    </div>
</template>

<script>
export default {
    name: 'App',
};
</script>
