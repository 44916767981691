<template>
    <div>
        <Header />
        <RouterView />
    </div>
</template>

<script>
import Header from '@/components/common/Header';

export default {
    components: {
        Header,
    },
};
</script>
