<template>
  <div v-show="visible" class="tooltip-content" :style="tooltipStyle">
    <!-- Adjusted SVG dimensions to 40x30 -->
    <svg width="40" height="30" viewBox="0 0 40 30" class="triangle-svg">
  <!-- Adjust polygon points to make the triangle base more narrow -->
  <polygon points="20,0 35,25 5,25" style="fill:red;"/>
  <!-- Bold the exclamation mark and adjust its position if necessary -->
  <text x="20" y="20" font-size="18" text-anchor="middle" fill="white" font-weight="bold">!</text>
</svg>

    <div class="message-box">
      {{ text }}
    </div>
  </div>
</template>


<script>
export default {
  name: 'Warning',
  props: ['visible', 'text', 'position'],
  computed: {
    tooltipStyle() {
      return {
        visibility: this.visible ? 'visible' : 'hidden',
        position: 'absolute',
        backgroundColor: 'yellow',
        borderRadius: '5px',
        padding: '10px',
        border: '1px solid #ccc',
        zIndex: '10000',
        boxShadow: '0px 0px 5px #aaa',
        maxWidth: '200px',
        wordWrap: 'break-word',
        fontSize: '14px',
        left: `${this.position.left}px`,
        top: `${this.position.top}px`,
      };
    }
  }
};
</script>

<style scoped>
.tooltip-content {
  position: relative; /* Ensure relative positioning for correct SVG placement */
   /* Adjust padding to accommodate the triangle height */
  display: inline-block; /* Adjust as necessary to fit your design */
}

.triangle-svg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* Position the triangle above the tooltip */
}

.message-box {
  text-align: center; /* Center the text below the triangle */
  margin-top: 30px;
  /* Other styling for the message box */
}
</style>
