import Vue from 'vue';
import Toasted from 'vue-toasted'

import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue';

import router from '@/router';
import store from '@/store/store';

import App from '@/views/App.vue';

import '@/styles/style.scss';

import '@/mixins/methods';

import aws_exports from '@/aws-exports';
Amplify.configure(aws_exports);

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import VueEvents from 'vue-events';
Vue.use(VueEvents);

import MaterialAllocationPlugin from './components/planning/MaterialAllocationPlugin';
import OverlayPlugin from './components/common/OverlayPlugin';
import WarningPlugin from './components/common/WarningPlugin';

Vue.use(MaterialAllocationPlugin);
Vue.use(OverlayPlugin);
Vue.use(WarningPlugin);


Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

Vue.use(Toasted)