const state = {
    adjustedBooking: [],
    
};

const getters = {
    adjustedBooking: (state) => {
        return state.adjustedBooking;
    },
};

const mutations = {
    setAdjustedBooking: (state, payload) => {
        let entry = find(state.adjustedBooking, { month: payload.month, segment: payload.segment, type: payload.type });
        if (!entry) {
            state.adjustedBooking.push(payload);
        } else {
            if (payload.units !== null) entry.units = parseInt(payload.units);
            if (payload.gross !== null) entry.gross = payload.gross;
        }
    },
};

const actions = {
    setAdjustedBooking: ({ commit }, payload) => {
        commit('setAdjustedBooking', payload);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
