export const monthsObject = [
  { name: "April", shortName: "Apr", value: 4 },
  { name: "May", shortName: "May", value: 5 },
  { name: "June", shortName: "Jun", value: 6 },
  { name: "July", shortName: "Jul", value: 7 },
  { name: "August", shortName: "Aug", value: 8 },
  { name: "September", shortName: "Sep", value: 9 },
  { name: "October", shortName: "Oct", value: 10 },
  { name: "November", shortName: "Nov", value: 11 },
  { name: "December", shortName: "Dec", value: 12 },
  { name: "January", shortName: "Jan", value: 1 },
  { name: "February", shortName: "Feb", value: 2 },
  { name: "March", shortName: "Mar", value: 3 },
];

export const currencySymbols = {
  EUR: "€",
  CAD: "$",
  CNY: "¥",
  USD: "$",
  DKK: "kr",
  SEK: "kr",
  NOK: "kr",
};
