<template>
    <div>
        <Header />
        <template v-if="!$route.meta.hideToolbars">
            <PlanningToolbar v-if="checkOnBauerBiz"/>
            <PlanningHeader />
        </template>
        <KeepAlive>
            <RouterView />
        </KeepAlive>
    </div>
</template>

<script>
import Header from '@/components/common/Header';
import PlanningHeader from '@/components/planning/Header';
import PlanningToolbar from '@/components/planning/Toolbar';

export default {
    components: {
        Header,
        PlanningHeader,
        PlanningToolbar,
    },
    computed :{
        checkOnBauerBiz(){
            return !this.$route.path.includes('uploadtobb');
        }
    }
};
</script>
