import Vue from "vue";
import { chain } from "lodash";
import store from "@/store/store";

// Create the MaterialAllocation Vue instance
const MaterialAllocation = new Vue({
  store,
  data() {
    return {
      upcEventBus: [],
      allMaterials: {},
      areMaterialsInitialized: false,
      areUPCsInitialized: false,
      isDirty: false,
      stepTwoDirty: false,
      isSavedUpcLoaded: false,
      isLoading: true,
      changeEvent: 0,
      uniqueSegments: [],
      uniqueFamilies: [],
      uniqueGenders: [],
      ordertypes: [
        {
          name: "launch",
          segments: [],
        },
        {
          name: "migrating",
          segments: [],
        },
      ],
    };
  },

  created() {
    this.$on("upcAllocated", () => {
      setTimeout(() => {
        this.emitEvents();
      }, 1000); // Delay of 3000 milliseconds (3 seconds)
    });
  },

  methods: {
    reset() {
      (this.upcEventBus = []), (this.allMaterials = {});
      this.areMaterialsInitialized = false;
      this.areUPCsInitialized = false;
      this.isInitialized = false;
      (this.isLoading = true), (this.isDirty = false), (this.changeEvent = 0);
      this.isSavedUpcLoaded = false;
      (this.uniqueSegments = []),
        (this.uniqueFamilies = []),
        (this.uniqueGenders = []),
        this.ordertypes.forEach((ordertype) => {
          ordertype.segments = [];
        });
    },

    emitEvents() {
      this.upcEventBus.forEach((upcupdate) => {
        this.emitUpcChange(upcupdate.material, upcupdate.month);
      });
      this.upcEventBus = [];
    },

    // Method to add a new segment to an ordertype
    addSegment(ordertypeName, segmentName, sort = 0) {
      const ordertype = this.ordertypes.find((ordertype) => ordertype.name === ordertypeName);
      if (ordertype) {
        const existingSegment = ordertype.segments.find((segment) => segment.name === segmentName);
        if (existingSegment) {
          console.warn(`Segment '${segmentName}' already exists in the ordertype '${ordertypeName}'.`);
          return;
        }

        const newSegment = {
          name: segmentName,
          months: Array(12)
            .fill(null)
            .map(() => ({ planned: 0, isCustom: false })), // Initialize months array
          families: [],
        };
        if (!this.uniqueSegments.find((s) => s.segment === segmentName)) {
          this.uniqueSegments.push({ segment: segmentName, sort: sort });
        }
        ordertype.segments.push(newSegment);
      }
    },

    // Method to add a new family to a segment
    addFamily(ordertypeName, segmentName, familyName) {
      const ordertype = this.ordertypes.find((ordertype) => ordertype.name === ordertypeName);
      if (ordertype) {
        const segment = ordertype.segments.find((segment) => segment.name === segmentName);
        if (segment) {
          const existingFamily = segment.families.find((family) => family.name === familyName);
          if (existingFamily) {
            console.warn(`Family '${familyName}' already exists in the segment '${segmentName}'.`);
            return;
          }

          const newFamily = {
            name: familyName,
            genders: [],
          };
          if (!this.uniqueFamilies.find((family) => family === familyName)) {
            this.uniqueFamilies.push(familyName);
          }
          segment.families.push(newFamily);
        }
      }
    },

    // Method to add a new gender to a family
    addGender(ordertypeName, segmentName, familyName, genderName, sort = 0) {
      const ordertype = this.ordertypes.find((ordertype) => ordertype.name === ordertypeName);
      if (ordertype) {
        const segment = ordertype.segments.find((segment) => segment.name === segmentName);
        if (segment) {
          const family = segment.families.find((family) => family.name === familyName);
          if (family) {
            const existingGender = family.genders.find((gender) => gender.name === genderName);
            if (existingGender) {
              console.warn(`Gender '${genderName}' already exists in the family '${familyName}'.`);
              return;
            }

            const newGender = {
              name: genderName,
              materials: {},
            };
            if (!this.uniqueGenders.find((g) => g.gender === genderName)) {
              this.uniqueGenders.push({ gender: genderName, sort: sort });
            }

            family.genders.push(newGender);
          }
        }
      }
    },

    // Method to add a new material to the MaterialAllocation object, including UPCs
    addMaterial(ordertypeName, segmentName, familyName, genderName, materialName, price, description, shelfdate) {
      const ordertype = this.ordertypes.find((ordertype) => ordertype.name === ordertypeName);
      if (ordertype) {
        const segment = ordertype.segments.find((segment) => segment.name === segmentName);
        if (segment) {
          const family = segment.families.find((family) => family.name === familyName);
          if (family) {
            const gender = family.genders.find((gender) => gender.name === genderName);
            if (gender) {
              if (gender.materials[materialName] != null) {
                console.warn(`Material '${materialName}' already exists for gender '${genderName}'.`);
                return;
              }
              const newMaterial = {
                changeEvent: 0,
                price,
                description: description,
                months: Array(12)
                  .fill(null)
                  .map(() => ({ planned: 0, upcPlanned: 0, isCustom: false })),
                upcs: [], // Initialize UPCs array
                shelfdate: shelfdate,
                segment: segmentName,
                type: ordertypeName,
                family: familyName,
                gender: genderName,
              };
              gender.materials[materialName] = newMaterial;
              this.allMaterials[materialName] = gender.materials[materialName];
            }
          }
        }
      }
    },
    addUpcToMaterial(ordertypeName, segmentName, familyName, genderName, materialName, upcNumber, dim1, dim2, weight) {
      const material = this.getMaterial(materialName);
      if (material) {
        // Check for existing UPC based on upcNumber instead of Dim1 and Dim2
        const existingUpc = material.upcs.find((upc) => upc.upcNumber === upcNumber);
        if (!existingUpc) {
          const newUpc = {
            upcNumber: upcNumber,
            Dim1: dim1,
            Dim2: dim2,
            weight: weight,
            months: Array(12)
              .fill(0)
              .map(() => ({ planned: 0, lastPlanned: 0, isCustom: false })), // Initialize months with planned values
          };
          material.upcs.push(newUpc);
        } else {
          console.warn(`UPC with upcNumber: '${upcNumber}' already exists for material '${materialName}'.`);
        }
      }
    },

    setPlannedUpcValue(materialName, upcNumber, month, value, isCustom = true, fromSave = false) {
      const material = this.getMaterial(materialName);
      if (material) {
        const upc = material.upcs.find((upc) => upc.upcNumber === upcNumber);
        if (upc && upc.months[month - 1]) {
          // Set the UPC's planned value and isCustom flag
          upc.months[month - 1].planned = value;
          upc.months[month - 1].isCustom = isCustom;
          if (fromSave) {
            upc.months[month - 1].lastPlanned = value;
          }

          let total = 0;
          material.upcs.forEach((upc) => {
            total += upc.months[month - 1].planned;
          });
          material.months[month - 1].upcPlanned = total;

          this.$emit("upcChanged", {
            materialName: materialName,
            upcNumber: upcNumber,
            upcPlanned: material.months[month - 1].upcPlanned,
            planned: material.months[month - 1].planned,
            month: month,
          });
        } else {
          console.warn(`UPC with number '${upcNumber}' not found or invalid month.`);
        }
      }
      this.$store.commit("setPlannedMaterialData", this.allMaterials);
    },

    // Method to get the planned value for a UPC
    getPlannedUpcValue(materialName, upcNumber, month) {
      const material = this.getMaterial(materialName);
      if (material) {
        const upc = material.upcs.find((upc) => upc.upcNumber === upcNumber);
        if (upc && upc.months[month - 1]) {
          return upc.months[month - 1].planned;
        } else {
          console.warn(`UPC with number '${upcNumber}' not found or invalid month.`);
          return 0;
        }
      }
      return 0;
    },

    getPlannedUpcValueForYear(materialName, upcNumber) {
      const material = this.getMaterial(materialName);
      const upc = material.upcs.find((upc) => upc.upcNumber === upcNumber);
      let total = 0;
      upc.months.forEach((mon) => {
        total += mon.planned;
      });
      return total;
    },
    getPlannedUpcPriceForYear(materialName, upcNumber, existingUnits = 0) {
      const material = this.getMaterial(materialName);
      return (this.getPlannedUpcValueForYear(materialName, upcNumber) + existingUnits) * material.price;
    },

    getPlannedUpcValuesForYear(materialName) {
      const upcTotals = [];
      const material = this.getMaterial(materialName);
      material.upcs.forEach((upc) => {
        let total = 0;
        upc.months.forEach((mon) => {
          total += mon.planned;
        });
        upcTotals.push({ upcNumber: upc.upcNumber, total: total, price: material.price });
      });
      return upcTotals;
    },

    // Method to update the weight of a UPC within a material
    updateUpcWeight(materialNumber, upcNumber, newWeight, market = false) {
      // Loop through all ordertypes to find the material
      let found = false; // Flag to indicate if the UPC was found and updated
      this.ordertypes.forEach((ordertype) => {
        ordertype.segments.forEach((segment) => {
          segment.families.forEach((family) => {
            family.genders.forEach((gender) => {
              // Iterate through the materials of the gender
              Object.values(gender.materials).forEach((material) => {
                // Check if this is the material we're looking for
                if (material && material.upcs) {
                  const upcIndex = material.upcs.findIndex((upc) => upc.upcNumber === upcNumber);
                  if (upcIndex !== -1) {
                    // Update the weight of the found UPC
                    if (market) {
                      material.upcs[upcIndex].marketWeight = newWeight;
                    } else {
                      material.upcs[upcIndex].weight = newWeight;
                    }
                    //         console.log(`Updated UPC ${upcNumber} in Material ${materialNumber} with new weight ${newWeight}.`);
                    found = true;
                    return; // Exit the current iteration
                  }
                }
              });
              if (found) return; // Break out of the loop once updated
            });
            if (found) return;
          });
          if (found) return;
        });
        if (found) return;
      });

      if (!found) {
        console.warn(`UPC ${upcNumber} not found in Material ${materialNumber}.`);
      }
    },

    initUPCFromSavedData(data, cseason) {
      this.getMaterialsForUPCAllocation().forEach((material) => {
        material.upcs.forEach((upc) => {
          upc.months.forEach((month) => {
            const mindex = upc.months.indexOf(month);
            const obj = data.filter(
              (x) =>
                x.material === parseInt(material.materialName) &&
                x.booking_date ===
                  `${mindex > 2 ? cseason : cseason + 1}-${mindex + 1 < 10 ? "0" + (mindex + 1) : mindex + 1}-01` &&
                x.upc === upc.upcNumber
            );
            month.lastPlanned = obj.length === 1 ? parseInt(obj[0].quantity) : 0;
          });
        });
      });
      this.changeEvent = this.changeEvent + 1;
    },

    exportUpcData() {
      const exportObject = [];
      this.ordertypes.forEach((ordertype) => {
        const ordertypeName = ordertype.name;
        ordertype.segments.forEach((segment) => {
          const segmentName = segment.name;
          segment.families.forEach((family) => {
            const familyName = family.name;
            family.genders.forEach((gender) => {
              Object.entries(gender.materials).forEach(([materialNumber, material]) => {
                material.upcs.forEach((upc) => {
                  let pattern = {
                    Description: "",
                    upc: "",
                    Family: "",
                    Segment: "",
                    dim1: "",
                    dim2: "",
                    Type: "",
                    WHSL: "",
                    Apr: 0,
                    May: 0,
                    Jun: 0,
                    Jul: 0,
                    Aug: 0,
                    Sep: 0,
                    Oct: 0,
                    Nov: 0,
                    Dec: 0,
                    Jan: 0,
                    Feb: 0,
                    Mar: 0,
                    "Units TL": 0,
                    "WHSL TL": 0,
                  };
                  pattern["Description"] = `(${materialNumber}) ${material.description}`;
                  pattern["upc"] = `${upc.upcNumber.toString()}`;
                  pattern["Family"] = familyName;
                  pattern["Segment"] = segmentName;
                  pattern["dim1"] = upc.Dim1;
                  pattern["dim2"] = upc.Dim2;
                  pattern["Type"] = ordertypeName;
                  pattern["WHSL"] = material.price;
                  pattern["Apr"] = upc.months[3].planned;
                  pattern["May"] = upc.months[4].planned;
                  pattern["Jun"] = upc.months[5].planned;
                  pattern["Jul"] = upc.months[6].planned;
                  pattern["Aug"] = upc.months[7].planned;
                  pattern["Sep"] = upc.months[8].planned;
                  pattern["Oct"] = upc.months[9].planned;
                  pattern["Nov"] = upc.months[10].planned;
                  pattern["Dec"] = upc.months[11].planned;
                  pattern["Jan"] = upc.months[0].planned;
                  pattern["Feb"] = upc.months[1].planned;
                  pattern["Mar"] = upc.months[2].planned;
                  const totalPlanned = upc.months.reduce((sum, month) => sum + month.planned, 0);
                  pattern["Units TL"] = totalPlanned;
                  pattern["WHSL TL"] = Math.round(totalPlanned * material.price);
                  if (totalPlanned > 0) exportObject.push(pattern);
                });
              });
            });
          });
        });
      });
      return exportObject;
    },

    getFlattenedUpcData(actuals) {
      const data = [];
      this.ordertypes.forEach((ordertype) => {
        ordertype.segments.forEach((segment) => {
          const segmentName = segment.name;
          segment.families.forEach((family) => {
            const familyName = family.name;
            family.genders.forEach((gender) => {
              const genderName = gender.name;
              Object.values(gender.materials).forEach((material) => {
                material.upcs.forEach((upc) => {
                  const totalUnits =
                    upc.months.reduce((sum, month) => sum + month.planned, 0) +
                    this.getSum(actuals, { upc_number: upc.upcNumber }, "units_sold");
                  data.push({
                    family: familyName,
                    gender: genderName,
                    segment: segmentName,
                    dim1: upc.Dim1,
                    dim2: upc.Dim2,
                    units: totalUnits,
                  });
                });
              });
            });
          });
        });
      });
      return data;
    },

    getSum(data, filterObj, key) {
      return chain(data).filter(filterObj).map(key).sum().value();
    },
    //sets the value of a material and messages change
    setPlannedMaterialValue(
      ordertypeName,
      segmentName,
      familyName,
      genderName,
      materialName,
      month,
      value,
      add = false,
      custom = true
    ) {
      const gender = this.ordertypes
        .find((o) => o.name === ordertypeName)
        .segments.find((s) => s.name === segmentName)
        .families.find((f) => f.name === familyName)
        .genders.find((g) => g.name === genderName);

      if (!gender) return;

      const material = gender.materials[materialName];

      if (!material) return;
      try {
        if (material.months[month - 1]) {
          if (add) {
            Vue.set(material.months[month - 1], "planned", material.months[month - 1].planned + value);
          } else {
            Vue.set(material.months[month - 1], "planned", parseInt(value));
          }
          Vue.set(material.months[month - 1], "isCustom", value === 0 ? false : custom);

          //   material.months[month - 1].upcPlanned = material.months[month - 1].planned;
        }
      } catch (e) {
        console.error(e);
      }
      this.changeEvent = this.changeEvent + 1;
      if (!this.isLoading && !this.isDirty) {
        this.isDirty = true;
      }
    },

    setPlannedMaterialValueByTypeAndSegment(
      ordertypeName,
      segmentName,
      materialName,
      month,
      value,
      add = false,
      custom = true
    ) {
      const ordertype = this.ordertypes.find((o) => o.name === ordertypeName);
      if (!ordertype) return;

      const segment = ordertype.segments.find((s) => s.name === segmentName);
      if (!segment) return;

      segment.families.forEach((family) => {
        family.genders.forEach((gender) => {
          const material = gender.materials[materialName];
          if (material && material.months[month - 1]) {
            if (add) {
              Vue.set(material.months[month - 1], "planned", material.months[month - 1].planned + value);
            } else {
              Vue.set(material.months[month - 1], "planned", value);
            }

            Vue.set(material.months[month - 1], "isCustom", value === 0 ? false : custom);
            //       material.months[month - 1].upcPlanned = material.months[month - 1].planned;
          }
        });
      });

      this.changeEvent = this.changeEvent + 1;
      this.isDirty = false;
    },
    hasCustomUpc(month) {
      let result = false;
      const materials = this.getMaterialsForUPCAllocation();
      for (const material in materials) {
        let upcTotal = 0;
        const upcs = materials[material].upcs;
        for (const upc in upcs) {
          upcTotal += upcs[upc].months[month - 1].planned;
        }

        result = upcTotal !== materials[material].months[month - 1].planned;

        if (result) break;
      }

      return result;
    },

    //gets all the materials in a given type and segment ex 'launch' / "Elite"
    getMaterialsForBooking(ordertypeName, segmentName) {
      const mats = [];
      const segment = this.ordertypes
        .find((o) => o.name === ordertypeName)
        .segments.find((s) => s.name === segmentName);

      for (const family in segment.families) {
        for (const gender in segment.families[family].genders) {
          for (const material in segment.families[family].genders[gender].materials) {
            mats.push(segment.families[family].genders[gender].materials[material]);
          }
        }
      }

      return mats;
    },

    getMonthlyTotalsByTypeAndSegment() {
      const results = [];

      this.ordertypes.forEach((ordertype) => {
        ordertype.segments.forEach((segment) => {
          // Initialize an object to hold the totals for each month
          const monthlyTotals = Array(12).fill(0);

          // Aggregate planned values for each month
          segment.families.forEach((family) => {
            family.genders.forEach((gender) => {
              Object.values(gender.materials).forEach((material) => {
                material.months.forEach((month, index) => {
                  if (month.planned > 0) {
                    monthlyTotals[index] += month.planned;
                  }
                });
              });
            });
          });

          // Add results for months with non-zero totals
          monthlyTotals.forEach((total, index) => {
            if (total > 0) {
              results.push({
                month: index + 1, // Adjusting for 1-based month numbering
                segment: segment.name,
                type: ordertype.name,
                units: total,
              });
            }
          });
        });
      });

      return results;
    },

    getMaterialsWithNonZeroPlans() {
      const results = [];

      this.ordertypes.forEach((ordertype) => {
        const ordertypeName = ordertype.name;

        ordertype.segments.forEach((segment) => {
          const segmentName = segment.name;
          segment.families.forEach((family) => {
            family.genders.forEach((gender) => {
              Object.entries(gender.materials).forEach(([materialNumber, material]) => {
                material.months.forEach((monthData, monthIndex) => {
                  if (monthData.planned > 0) {
                    results.push({
                      materialNumber: materialNumber,
                      price: gender.materials[materialNumber].price,
                      month: monthIndex + 1,
                      planned: monthData.planned,
                      segmentName: segmentName,
                      ordertypeName: ordertypeName,
                    });
                  }
                });
              });
            });
          });
        });
      });

      return results;
    },

    getMaterialsForUPCAllocation() {
      const results = [];
      this.ordertypes.forEach((ordertype) => {
        ordertype.segments.forEach((segment) => {
          segment.families.forEach((family) => {
            family.genders.forEach((gender) => {
              for (const materialName in gender.materials) {
                let mat = gender.materials[materialName];
                mat.materialName = materialName;
                results.push(mat);
              }
            });
          });
        });
      });

      return results;
    },

    // Helper method to get a material object
    getMaterial(materialName) {
      //  const gender = this.ordertypes
      //   .find(o => o.name === ordertypeName)
      //   .segments.find(s => s.name === segmentName)
      //   .families.find(f => f.name === familyName)
      //   .genders.find(g => g.name === genderName);

      // return gender ? gender.materials[materialName] : null;
      return this.allMaterials[materialName];
    },

    //intializes all the materials from fetchmaterials. This does not set the value of
    initializeFromData(materialsArray, pgsarray, genderarray) {
      this.reset();
      materialsArray.forEach((material) => {
        const { amount, gender, family, segment, type, shelfdate } = material;

        // if (!this.checkSegmentDeadline(month, shelfDate)) {
        //   console.log("true");
        // }

        if (!this.ordertypes.some((ordertype) => ordertype.name.toLowerCase() === type.toLowerCase())) {
          this.ordertypes.push({
            name: type,
            segments: [],
          });
        }

        let psort = 0;
        try {
          psort = parseInt(pgsarray.find((x) => x.name === segment).sort.toString());
        } catch (e) {
          console.warn("no psort order");
        }
        let gsort = 0;
        try {
          gsort = parseInt(genderarray.find((x) => x.gender === gender).sort.toString());
        } catch (e) {
          console.warn("no gsort order");
        }

        // Add segment, family, and gender using existing methods
        this.addSegment(type, segment, psort);
        this.addFamily(type, segment, family);
        this.addGender(type, segment, family, gender, gsort);

        const materialName = material.material;

        this.addMaterial(type, segment, family, gender, materialName, amount, material.description, shelfdate);
      });

      this.$store.commit("setPlannedMaterialData", this.allMaterials);

      this.uniqueSegments.sort((a, b) => {
        const sortDifference = a.sort - b.sort;

        // If 'sort' values are equal, sort alphabetically by 'segment'
        if (sortDifference === 0) {
          return a.segment.localeCompare(b.segment);
        }

        return sortDifference;
      });

      this.uniqueGenders.sort((a, b) => {
        const sortDifference = a.sort - b.sort;

        // If 'sort' values are equal, sort alphabetically by 'segment'
        if (sortDifference === 0) {
          return a.gender.localeCompare(b.gender);
        }

        return sortDifference;
      });

      this.isLoading = false;
      this.areMaterialsInitialized = true;
    },

    initializeUpcs(upcdata) {
      for (const index in upcdata) {
        const upc = upcdata[index];
        this.addUpcToMaterial(
          upc.type,
          upc.segment,
          upc.family,
          upc.gender,
          upc.material,
          upc.upc,
          upc.dim1,
          upc.dim2,
          upc.weight
        );
      }
      this.areUPCsInitialized = true;
      this.changeEvent = this.changeEvent + 1;
    },

    isSegmentMonthCustom(ordertypeName, segmentName, month) {
      const segment = this.ordertypes
        .find((o) => o.name === ordertypeName)
        .segments.find((s) => s.name === segmentName);

      for (const family in segment.families) {
        for (const gender in segment.families[family].genders) {
          for (const material in segment.families[family].genders[gender].materials) {
            if (segment.families[family].genders[gender].materials[material].months[month - 1].isCustom) {
              return true;
            }
          }
        }
      }
      return false;
    },
    doUPCAllocation(revertToSaved = false) {
      this.getMaterialsForUPCAllocation().forEach((material) => {
        for (const month in material.months) {
          this.distributeMaterialPlannedUnitsToUpc(material, month, revertToSaved);
        }
      });
      this.$emit("upcAllocated", {});
    },

    distributeMaterialPlannedUnitsToUpc(material, monthIndex, revertToSaved = false) {
      // Set initial planned units for the month from the material's month data
      material.months[monthIndex].upcPlanned = material.months[monthIndex].planned;

      if (revertToSaved) {
        material.upcs.forEach((upc) => {
          upc.months[monthIndex].planned = upc.months[monthIndex].lastPlanned;
          upc.months[monthIndex].isCustom = false;
        });
        this.upcEventBus.push({ material: material, month: parseInt(monthIndex) + 1 });
        return;
      }

      const totalPlanned = material.months[monthIndex].planned;
      let totalAllocated = 0;

      // Determine if normal weight should be used based on if any UPC has a positive weight
      const useNormalWeight = material.upcs.some((upc) => upc.weight > 0);
      let isMarketWeightNonZero = true;
      if (!useNormalWeight) {
        isMarketWeightNonZero = material.upcs.some((upc) => upc.marketWeight > 0);
      }

      if (!useNormalWeight && !isMarketWeightNonZero) {
        const numUPCs = material.upcs.length;
        if (numUPCs === 0) {
          console.error("Error: No UPCs are available.");
          console.error(material);
          return; // Exit if there are no UPCs to allocate to
        }

        const fullPassCount = Math.floor(totalPlanned / numUPCs);
        const remainder = totalPlanned % numUPCs;

        // Distribute the full passes
        material.upcs.forEach((upc) => {
          upc.months[monthIndex].planned = fullPassCount;
        });

        // Distribute the remainder
        for (let i = 0; i < remainder; i++) {
          material.upcs[i].months[monthIndex].planned += 1;
        }
      } else {
        // First pass: Allocate based on weight, floor the result, and sum allocations
        material.upcs.forEach((upc) => {
          const weight = useNormalWeight ? upc.weight : upc.marketWeight;
          const allocation = Math.floor(totalPlanned * weight);
          upc.months[monthIndex].planned = allocation;
          upc.months[monthIndex].isCustom = false;
          totalAllocated += allocation;
        });

        let remainingToAllocate = totalPlanned - totalAllocated;

        // Prepare UPCs for redistribution of the remaining units
        let eligibleUpcs = material.upcs
          .filter((upc) => (useNormalWeight ? upc.weight : upc.marketWeight) > 0)
          .map((upc) => ({
            upc: upc,
            remainder: (totalPlanned * (useNormalWeight ? upc.weight : upc.marketWeight)) % 1,
          }))
          .sort((a, b) => b.remainder - a.remainder) // Sort by the fractional remainder descending
          .map((upcInfo) => upcInfo.upc); // Extract the upc objects back out

        // Distribute remaining units, prioritizing those with largest fractional part above floor
        for (let i = 0; i < remainingToAllocate; i++) {
          eligibleUpcs[i % eligibleUpcs.length].months[monthIndex].planned += 1;
        }
      }
      this.upcEventBus.push({ material: material, month: parseInt(monthIndex) + 1 });
      // Optional debug log to track final distributions
      // console.log(`Distributed all to UPCs. Total Planned: ${totalPlanned}, Total Allocated: ${totalAllocated + remainingToAllocate}.`);
    },
    emitUpcChange(material, month) {
      let total = 0;
      material.upcs.forEach((upc) => {
        try {
          total += upc.months[month - 1].planned;
        } catch (e) {
          console.error(" emit failed", upc, month - 1, material);
        }
      });
      material.months[month - 1].upcPlanned = total;

      this.$emit("upcChanged", {
        materialName: material.materialName,
        upcPlanned: material.months[month - 1].upcPlanned,
        planned: material.months[month - 1].planned,
        month: month,
        upcNumber: null,
      });
    },

    packageUpcForSave(soldto_id, category, season, user, cseason) {
      const upcData = [];
      this.getMaterialsForUPCAllocation().forEach((material) => {
        for (const month in material.months) {
          material.upcs.forEach((upc) => {
            {
              upc.months[month].lastPlanned = upc.months[month];
              if (upc.months[month].planned > 0) {
                const mindex = parseInt(month);
                let modelObject = {
                  soldto_id: soldto_id,
                  category: category,
                  season: season,
                  modified_by: user,
                  upc: upc.upcNumber,
                  booking_date: `${mindex > 2 ? cseason : cseason + 1}/${
                    mindex + 1 < 10 ? "0" + (mindex + 1) : mindex + 1
                  }/01`,
                  quantity: upc.months[month].planned,
                  material_number: parseInt(material.materialName),
                };
                upcData.push(modelObject);
              }
            }
          });
        }
      });

      return upcData;
    },

    checkSegmentDeadline(month, shelfDate) {
      const year = sessionStorage.getItem("seasonYear");
      const columndate = new Date(month < 4 ? year + 1 : year, month - 1, 1);
      const sdate = this.getDateInEST(shelfDate);
      const adjustedShelfDate = new Date(sdate.getFullYear(), sdate.getMonth(), 1);
      return columndate >= adjustedShelfDate;
    },

    //gets the value of a material
    getPlannedMaterialValue(ordertypeName, segmentName, familyName, genderName, materialName, month, useUpc = false) {
      const gender = this.ordertypes
        .find((o) => o.name === ordertypeName)
        .segments.find((s) => s.name === segmentName)
        .families.find((f) => f.name === familyName)
        .genders.find((g) => g.name === genderName);

      if (!gender) return 0;

      const material = gender.materials[materialName];

      if (!material) return 0;

      const shelfdate = material.shelfdate;
      if (!this.checkSegmentDeadline(month, shelfdate)) {
        return 0;
      }

      if (material.months[month - 1]) {
        // Adjust month to 0-based index
        if (useUpc) {
          // let total = 0;
          // for (const upc in material.upcs) {
          //   total += parseInt(material.upcs[upc].months[month - 1].planned)
          // }

          return material.months[month - 1].upcPlanned;
        } else {
          return material.months[month - 1].planned;
        }
      }

      return 0; // Return 0 if the month is out of range or material doesn't exist for that month
    },

    getPlannedMaterialPrice(ordertypeName, segmentName, familyName, genderName, materialName) {
      const gender = this.ordertypes
        .find((o) => o.name === ordertypeName)
        .segments.find((s) => s.name === segmentName)
        .families.find((f) => f.name === familyName)
        .genders.find((g) => g.name === genderName);

      if (!gender) return 0;

      const material = gender.materials[materialName];

      if (!material) return 0;
      return material.price;
    },

    plannedUnitsByYearForMaterialName(materialName, useUpc = false) {
      const material = this.allMaterials[materialName];

      let total = 0;

      material.months.forEach((mon) => {
        total += useUpc ? mon.upcPlanned : mon.planned;
      });

      return total;
    },

    plannedUnitsByYearForMaterial(ordertypeName, segmentName, familyName, genderName, material, useUpc = false) {
      let total = 0;

      for (let month = 1; month <= 12; month++) {
        try {
          total += this.getPlannedMaterialValue(
            ordertypeName,
            segmentName,
            familyName,
            genderName,
            material,
            month,
            useUpc
          );
        } catch (e) {
          console.warn(e);
        }
      }

      return total;
    },

    plannedPriceByYearForMaterial(ordertypeName, segmentName, familyName, genderName, material, useUpc = false) {
      return (
        this.plannedUnitsByYearForMaterial(ordertypeName, segmentName, familyName, genderName, material, useUpc) *
        this.getPlannedMaterialPrice(ordertypeName, segmentName, familyName, genderName, material)
      );
    },

    plannedPriceByYearForMaterialWithActuals(material, useUpc = false, actuals = 0) {
      const mat = this.allMaterials[parseInt(material)];
      let tot = 0;
      mat.months.forEach((month) => {
        tot += useUpc ? month.upcPlanned : month.planned;
      });
      return (tot + actuals) * mat.price;
    },

    // Method to calculate total units by month for a given gender
    plannedUnitsByMonthForGender(ordertypeName, segmentName, familyName, genderName, month, useUpc = false) {
      const gender = this.ordertypes
        .find((o) => o.name === ordertypeName)
        .segments.find((s) => s.name === segmentName)
        .families.find((f) => f.name === familyName)
        .genders.find((g) => g.name === genderName);

      if (!gender) return 0;

      let total = 0;

      for (const materialName in gender.materials) {
        total += this.getPlannedMaterialValue(
          ordertypeName,
          segmentName,
          familyName,
          genderName,
          materialName,
          month,
          useUpc
        ); // Adjust month to 0-based index
      }

      return total;
    },

    // Method to calculate total price by month for a given gender
    plannedPriceByMonthForGender(ordertypeName, segmentName, familyName, genderName, month, useUpc = false) {
      const gender = this.ordertypes
        .find((o) => o.name === ordertypeName)
        .segments.find((s) => s.name === segmentName)
        .families.find((f) => f.name === familyName)
        .genders.find((g) => g.name === genderName);

      if (!gender) return 0;

      let total = 0;

      for (const materialName in gender.materials) {
        const material = gender.materials[materialName];

        total +=
          this.getPlannedMaterialValue(
            ordertypeName,
            segmentName,
            familyName,
            genderName,
            materialName,
            month,
            useUpc
          ) * material.price; // Adjust month to 0-based index
      }

      return total;
    },

    plannedUnitsByYearForGender(ordertypeName, segmentName, familyName, genderName, useUpc = false) {
      let total = 0;

      for (let month = 1; month <= 12; month++) {
        total += this.plannedUnitsByMonthForGender(ordertypeName, segmentName, familyName, genderName, month, useUpc);
      }

      return total;
    },

    // Method to calculate total price by month for a given gender
    plannedPriceByYearForGender(ordertypeName, segmentName, familyName, genderName, useUpc = false) {
      let total = 0;

      for (let month = 1; month <= 12; month++) {
        total += this.plannedPriceByMonthForGender(ordertypeName, segmentName, familyName, genderName, month, useUpc);
      }

      return total;
    },

    plannedUnitsByYearForAllGenderOccurencesByType(ordertypeName, genderName) {
      const ordertype = this.ordertypes.find((o) => o.name === ordertypeName);
      if (!ordertype) return 0;

      let total = 0;

      for (const segment of ordertype.segments) {
        for (const family of segment.families) {
          const gender = family.genders.find((g) => g.name === genderName);
          if (gender) {
            for (let month = 1; month <= 12; month++) {
              total += this.plannedUnitsByMonthForGender(ordertypeName, segment.name, family.name, genderName, month);
            }
          }
        }
      }

      return total;
    },
    plannedPriceByYearForAllGenderOccurencesByType(ordertypeName, genderName) {
      const ordertype = this.ordertypes.find((o) => o.name === ordertypeName);
      if (!ordertype) return 0;

      let total = 0;

      for (const segment of ordertype.segments) {
        for (const family of segment.families) {
          const gender = family.genders.find((g) => g.name === genderName);
          if (gender) {
            for (let month = 1; month <= 12; month++) {
              total += this.plannedPriceByMonthForGender(ordertypeName, segment.name, family.name, genderName, month);
            }
          }
        }
      }

      return total;
    },
    // Method to calculate total units by month for a given family
    plannedUnitsByMonthForFamily(ordertypeName, segmentName, familyName, month, useUpc = false) {
      const family = this.ordertypes
        .find((o) => o.name === ordertypeName)
        .segments.find((s) => s.name === segmentName)
        .families.find((f) => f.name === familyName);

      if (!family) return 0;

      let total = 0;

      for (const gender of family.genders) {
        total += this.plannedUnitsByMonthForGender(ordertypeName, segmentName, familyName, gender.name, month, useUpc);
      }

      return total;
    },

    // Method to calculate total price by month for a given family
    plannedPriceByMonthForFamily(ordertypeName, segmentName, familyName, month, useUpc = false) {
      const family = this.ordertypes
        .find((o) => o.name === ordertypeName)
        .segments.find((s) => s.name === segmentName)
        .families.find((f) => f.name === familyName);

      if (!family) return 0;

      let total = 0;

      for (const gender of family.genders) {
        total += this.plannedPriceByMonthForGender(ordertypeName, segmentName, familyName, gender.name, month, useUpc);
      }

      return total;
    },

    plannedUnitsByYearForFamily(ordertypeName, segmentName, familyName, useUpc = false) {
      const family = this.ordertypes
        .find((o) => o.name === ordertypeName)
        .segments.find((s) => s.name === segmentName)
        .families.find((f) => f.name === familyName);

      if (!family) return 0;

      let total = 0;

      for (const gender of family.genders) {
        total += this.plannedUnitsByYearForGender(ordertypeName, segmentName, familyName, gender.name, useUpc);
      }

      return total;
    },

    plannedUnitsByYearForAllFamilyOccurencesByType(ordertypeName, familyName) {
      const ordertype = this.ordertypes.find((o) => o.name === ordertypeName);
      if (!ordertype) return 0;

      let total = 0;

      for (const segment of ordertype.segments) {
        const family = segment.families.find((f) => f.name === familyName);
        if (family) {
          for (const gender of family.genders) {
            total += this.plannedUnitsByYearForGender(ordertypeName, segment.name, familyName, gender.name);
          }
        }
      }

      return total;
    },

    plannedPriceByYearForAllFamilyOccurencesByType(ordertypeName, familyName) {
      const ordertype = this.ordertypes.find((o) => o.name === ordertypeName);
      if (!ordertype) return 0;

      let total = 0;

      for (const segment of ordertype.segments) {
        const family = segment.families.find((f) => f.name === familyName);
        if (family) {
          for (const gender of family.genders) {
            total += this.plannedPriceByYearForGender(ordertypeName, segment.name, familyName, gender.name);
          }
        }
      }

      return total;
    },

    // Method to calculate total price by month for a given family
    plannedPriceByYearForFamily(ordertypeName, segmentName, familyName, useUpc = false) {
      const family = this.ordertypes
        .find((o) => o.name === ordertypeName)
        .segments.find((s) => s.name === segmentName)
        .families.find((f) => f.name === familyName);

      if (!family) return 0;

      let total = 0;

      for (const gender of family.genders) {
        total += this.plannedPriceByYearForGender(ordertypeName, segmentName, familyName, gender.name, useUpc);
      }

      return total;
    },

    // Method to calculate total units by month for a given segment
    plannedUnitsByMonthForSegment(ordertypeName, segmentName, month, useUpc = false) {
      const segment = this.ordertypes
        .find((o) => o.name === ordertypeName)
        .segments.find((s) => s.name === segmentName);

      if (!segment) return 0;

      let total = 0;

      for (const family of segment.families) {
        total += this.plannedUnitsByMonthForFamily(ordertypeName, segmentName, family.name, month, useUpc);
      }

      return total;
    },

    // Method to calculate total price by month for a given segment
    plannedPriceByMonthForSegment(ordertypeName, segmentName, month, useUpc = false) {
      const segment = this.ordertypes
        .find((o) => o.name === ordertypeName)
        .segments.find((s) => s.name === segmentName);

      if (!segment) return 0;

      let total = 0;

      for (const family of segment.families) {
        total += this.plannedPriceByMonthForFamily(ordertypeName, segmentName, family.name, month, useUpc);
      }

      return total;
    },

    plannedUnitsByYearForSegment(ordertypeName, segmentName, useUpc = false) {
      const segment = this.ordertypes
        .find((o) => o.name === ordertypeName)
        .segments.find((s) => s.name === segmentName);

      if (!segment) return 0;

      let total = 0;

      for (const family of segment.families) {
        total += this.plannedUnitsByYearForFamily(ordertypeName, segmentName, family.name, useUpc);
      }

      return total;
    },

    // Method to calculate total price by month for a given segment
    plannedPriceByYearForSegment(ordertypeName, segmentName, useUpc = false) {
      const segment = this.ordertypes
        .find((o) => o.name === ordertypeName)
        .segments.find((s) => s.name === segmentName);

      if (!segment) return 0;

      let total = 0;

      for (const family of segment.families) {
        total += this.plannedPriceByYearForFamily(ordertypeName, segmentName, family.name, useUpc);
      }

      return total;
    },

    // Method to calculate total units by month for a given ordertype
    plannedUnitsByMonthForOrdertype(ordertypeName, month, useUpc = false) {
      const ordertype = this.ordertypes.find((o) => o.name === ordertypeName);
      if (!ordertype) return 0;

      let total = 0;

      for (const segment of ordertype.segments) {
        total += this.plannedUnitsByMonthForSegment(ordertypeName, segment.name, month, useUpc);
      }

      return total;
    },

    // Method to calculate total price by month for a given ordertype
    plannedPriceByMonthForOrdertype(ordertypeName, month, useUpc = false) {
      const ordertype = this.ordertypes.find((o) => o.name === ordertypeName);

      if (!ordertype) return 0;

      let total = 0;

      for (const segment of ordertype.segments) {
        total += this.plannedPriceByMonthForSegment(ordertypeName, segment.name, month, useUpc);
      }

      return total;
    },

    // Method to calculate total units by year for a given ordertype
    plannedUnitsByYearForOrdertype(ordertypeName, useUpc = false) {
      const ordertype = this.ordertypes.find((o) => o.name === ordertypeName);

      if (!ordertype) return 0;

      let total = 0;

      for (const segment of ordertype.segments) {
        total += this.plannedUnitsByYearForSegment(ordertypeName, segment.name, useUpc);
      }

      return total;
    },

    // Method to calculate total price by year for a given ordertype
    plannedPriceByYearForOrdertype(ordertypeName, useUpc = false) {
      const ordertype = this.ordertypes.find((o) => o.name === ordertypeName);

      if (!ordertype) return 0;

      let total = 0;

      for (const segment of ordertype.segments) {
        total += this.plannedPriceByYearForSegment(ordertypeName, segment.name, useUpc);
      }

      return total;
    },

    // Method to calculate total units by year for all ordertypes combined
    plannedUnitsByMonthForAllOrdertypes(month, useUpc = false) {
      let total = 0;

      for (const ordertype of this.ordertypes) {
        total += this.plannedUnitsByMonthForOrdertype(ordertype.name, month, useUpc);
      }

      return total;
    },

    // Method to calculate total units by year for all ordertypes combined
    plannedPriceByMonthForAllOrdertypes(month, useUpc = false) {
      let total = 0;

      for (const ordertype of this.ordertypes) {
        total += this.plannedPriceByMonthForOrdertype(ordertype.name, month, useUpc);
      }

      return total;
    },

    // Method to calculate total units by year for all ordertypes combined
    plannedUnitsByYearForAllOrdertypes(useUpc = false) {
      let total = 0;

      for (const ordertype of this.ordertypes) {
        total += this.plannedUnitsByYearForOrdertype(ordertype.name, useUpc);
      }

      return total;
    },

    // Method to calculate total price by year for all ordertypes combined
    plannedPriceByYearForAllOrdertypes(useUpc = false) {
      let total = 0;

      for (const ordertype of this.ordertypes) {
        total += this.plannedPriceByYearForOrdertype(ordertype.name, useUpc);
      }

      return total;
    },
  },
});

export default MaterialAllocation;
