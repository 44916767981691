// In OverlayPlugin.js
import overlayInstance from './Overlay';

const OverlayPlugin = {
  install(Vue) {
    Vue.prototype.$overlay = {
      show: (message) => {
        overlayInstance.message = message;
        overlayInstance.visible = true;
      },
      hide: (message) => {
        if (message) {
          overlayInstance.message = message;
          setTimeout(() => {
            overlayInstance.visible = false;
          }, 3000); 
        } else {
          overlayInstance.visible = false;
        }
      }
    };
  }
};

export default OverlayPlugin;
