const state = {
    materialFamily: [],
    materialGender: [],
    materialSegment: [],
    allUpcData: [],
    upcFamily: [],
    upcGender: [],
    upcSegment: [],
    upcDim: [],
    splitSelectedValue: '',
    refreshData: false,
    bookingData: [],
    sellThruData: [],
    countryData: [],
    regionData: [],
    marketData: [],
    tableUpcLoader: true
};

const getters = {
    materialFamily: (state) => state.materialFamily,
    materialGender: (state) => state.materialGender,
    materialSegment: (state) => state.materialSegment,
    allUpcData: (state) => {
        return state.allUpcData;
    },
    bookingData: (state) => {
        return state.bookingData;
    },
    tableUpcLoader: (state) => {
        return state.tableUpcLoader
    },
    sellThruData: (state) => {
        return state.sellThruData
    },
    countryData: (state) => {
        return state.countryData
    },
    regionData: (state) => {
        return state.regionData
    },
    marketData: (state) => {
        return state.marketData
    },
    upcFamily: (state) => {
        return state.upcFamily;
    },
    upcGender: (state) => {
        return state.upcGender;
    },
    upcSegment: (state) => {
        return state.upcSegment;
    },
    upcDim: (state) => {
        return state.upcDim;
    },
    splitSelectedValue: (state) => {
        return state.splitSelectedValue;
    },
    refreshData: (state) => {
        return state.refreshData;
    },
};

const mutations = {
    setMaterialFamily: (state, payload) => {
        state.materialFamily = payload;
    },
    setMaterialGender: (state, payload) => {
        state.materialGender = payload;
    },
    setBookingData: (state, payload) => {
        state.bookingData = payload;
    },
    setTableUpcLoader: (state, payload) => {
        state.tableUpcLoader = payload;
    },
    setSellThruData: (state, payload) => {
        state.sellThruData = payload;
    },
    setCountryData: (state, payload) => {
        state.countryData = payload
    },
    setRegionData: (state, payload) => {
        state.regionData = payload
    },
    setMarketData: (state, payload) => {
        state.marketData = payload
    },
    resetFetchUpcStores : (state) => {
        state.sellThruData = [];
        state.countryData = [];
        state.regionData = [] ;
        state.marketData = [];
    },
    setMaterialSegment: (state, payload) => {
        state.materialSegment = payload;
    },
    setAllUpcData: (state, payload) => {
        state.allUpcData = payload;
    },
    setUpcFamily: (state, payload) => {
        state.upcFamily = payload;
    },
    setUpcGender: (state, payload) => {
        state.upcGender = payload;
    },
    setUpcSegment: (state, payload) => {
        state.upcSegment = payload;
    },
    setUpcDim: (state, payload) => {
        state.upcDim = payload;
    },
    // setMaterialSplit: (state, payload) => {
    //     state.splitSelected = payload;
    // },
    setMaterialSplitValue: (state, payload) => {
        state.splitSelectedValue = payload;
    },
    // setSplitId: (state, payload) => {
    //     state.splitId = payload;
    // },
    // setDisableSave: (state, payload) => {
    //     state.disableSave = payload;
    // },
    // setExportCSV: (state, payload) => {
    //     state.exportCSV = payload;
    // },
    // setCSV: (state, payload) => {
    //     state.getCSV = payload;
    // },
    setRefreshData: (state, payload) => {
        state.refreshData = payload;
    },
};

const actions = {
    setMaterialFamily: ({ commit }, payload) => {
        commit('setMaterialFamily', payload);
    },
    setMaterialGender: ({ commit }, payload) => {
        commit('setMaterialGender', payload);
    },
    setMaterialSegment: ({ commit }, payload) => {
        commit('setMaterialSegment', payload);
    },
    // setUpdateMaterialData: ({ commit }, payload) => {
    //     commit('setUpdateMaterialData', payload);
    // },
    // setMaterialsIndex: ({ commit }, payload) => {
    //     commit('setMaterialsIndex', payload);
    // },
    // setAllMaterialData: ({ commit }, payload) => {
    //     commit('setAllMaterialData', payload);
    // },
    setAllUpcData: ({ commit }, payload) => {
        commit('setAllUpcData', payload);
    },
    setUpcFamily: ({ commit }, payload) => {
        commit('setUpcFamily', payload);
    },
    setUpcGender: ({ commit }, payload) => {
        commit('setUpcGender', payload);
    },
    setUpcSegment: ({ commit }, payload) => {
        commit('setUpcSegment', payload);
    },
    setUpcDim: ({ commit }, payload) => {
        commit('setUpcDim', payload);
    },
    // setMaterialSplit: ({ commit }, payload) => {
    //     commit('setMaterialSplit', payload);
    // },
    // setSplitId: ({ commit }, payload) => {
    //     commit('setSplitId', payload);
    // },
    // setDisableSave: ({ commit }, payload) => {
    //     commit('setDisableSave', payload);
    // },
    // setExportCSV: ({ commit }, payload) => {
    //     commit('setExportCSV', payload);
    // },
    // setCSV: ({ commit }, payload) => {
    //     commit('setCSV', payload);
    // },
    setRefreshData: ({ commit }, payload) => {
        commit('setRefreshData', payload);
    },
    setBookingData: ({ commit }, payload) => {
        commit('setBookingData', payload);
    },
    setTableUpcLoader: ({ commit }, payload) => {
        commit('setTableUpcLoader', payload);
    },
    setSellThruData: ({ commit }, payload) => {
        commit('setSellThruData', payload);
    },
    setCountryData: ({ commit }, payload) => {
        commit('setCountryData', payload);
    },
    setRegionData: ({ commit }, payload) => {
        commit('setRegionData', payload);
    },
    setMarketData: ({ commit }, payload) => {
        commit('setMarketData', payload);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
