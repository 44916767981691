import { cloneDeep, find } from "lodash";

import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import constants from "@/store/modules/constants";
import planning from "@/store/modules/planning";
import bookings from "@/store/modules/bookings";
import material from "@/store/modules/material";

Vue.use(Vuex);

const ADMIN_SALES_REP_IDS = ["9160929"];

const PREVIOUS_SEASONS_DATA = [
  { year: 0, season: "", data: [], repeatUnits: [], sellThruUnits: [], grossTotal: 0, unitsTotal: 0 },
  { year: 0, season: "", data: [], repeatUnits: [], sellThruUnits: [], grossTotal: 0, unitsTotal: 0 },
];

const SEGMENT_LEVEL_DATA = {
  total: 1,
  launchTotal: 1,
  launch: {
    Elite: 1,
    Performance: 1,
    Recreational: 1,
    All: 1,
    Seasonal: 1,
    Core: 1,
  },
  migratingTotal: 1,
  migrating: {
    Elite: 1,
    Performance: 1,
    Recreational: 1,
    All: 1,
    Seasonal: 1,
    Core: 1,
  },
};

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "BauerAPT",
      paths: [],
    }),
  ],
  modules: {
    constants,
    material,
    planning,
    bookings,
  },
  state: {
    user: null,
    suggestedData: [],
    firstDimension: {},
    secondDimension: {},
    plannedMaterialData: {},
    selectedAllocationMethod: "",
    actuals: [],
    inventory: [],
    openOrders: [],
    stockUnits: [],
    historicGrowth: 0,
    previousSeasonsTotal: 0,
    suggestedBookings: [],
    previousSeasonsData: cloneDeep(PREVIOUS_SEASONS_DATA),
    materialData: [],
    segmentLevelData: cloneDeep(SEGMENT_LEVEL_DATA),
    wasLastSaveFromSaveButton: true,
    userChangedFlag: false,
    customSavedAllocationValues: {},
    calculatedAllocationValues: {},
    unsavedChangesFlag: false,
    products: [],
    discardedDecimals: {},
    earliestMonthsCanPlan: [],
    latestMonthsCanPlan: [],
    stockUnitsAsOf: "",
    allocationRemainders: {},
    tableUpcLoader: true,
    useCalculated: true,
    catalogId: "",
    originalMaterialAllocation: null,
  },

  getters: {
    useCalculated: (state) => state.useCalculated,
    user: (state) => state.user,
    catalogId: (state) => state.catalogId,
    suggestedData: (state) => state.suggestedData,
    firstDimension: (state) => state.firstDimension,
    secondDimension: (state) => state.secondDimension,
    plannedMaterialData: (state) => state.plannedMaterialData,
    selectedAllocationMethod: (state) => state.selectedAllocationMethod,
    actuals: (state) => state.actuals,
    inventory: (state) => state.inventory,
    openOrders: (state) => state.openOrders,
    stockUnits: (state) => state.stockUnits,
    historicGrowth: (state) => state.historicGrowth,
    previousSeasonsTotal: (state) => state.previousSeasonsTotal,
    suggestedBookings: (state) => state.suggestedBookings,
    previousSeasonsData: (state) => state.previousSeasonsData,
    materialData: (state) => state.materialData,
    segmentLevelData: (state) => state.segmentLevelData,
    stockUnitsAsOf: (state) => state.stockUnitsAsOf,
    products: (state) => state.products,
    tableUpcLoader: (state) => state.tableUpcLoader,
    customSavedAllocationValues: (state) => state.customSavedAllocationValues,
    calculatedAllocationValues: (state) => state.calculatedAllocationValues,
    currentSeasonYear: () => {
      let currentYear = new Date().getFullYear();
      //If we are in April or later, we are in the next season
      if (new Date().getMonth() > 2) currentYear = currentYear + 1;
      return currentYear;
    },
    currentSeason: () => {
      let currentYear = new Date().getFullYear();
      //If we are in April or later, we are in the next season
      if (new Date().getMonth() > 2) currentYear = currentYear + 1;
      return `S'${currentYear.toString().slice(-2)}`;
    },
    previousSeason: () => {
      let currentYear = new Date().getFullYear();
      if (new Date().getMonth() < 3) {
        currentYear = currentYear - 1;
      }
      return `S'${currentYear.toString().slice(-2)}`;
    },
    twoYearsAgoSeason: () => {
      let currentYear = new Date().getFullYear();
      currentYear = currentYear - 1;
      if (new Date().getMonth() < 3) {
        currentYear = currentYear - 1;
      }
      return `S'${currentYear.toString().slice(-2)}`;
    },
    //Returns April CURRENTSEASONYEAR - March CURRENTSEASONYEAR + 1
    currentSeasonMonthAndYearRange: (_, getters) => {
      return `April ${getters.currentSeasonYear} - March ${getters.currentSeasonYear + 1}`;
    },
    //Returns April PREVEASONYEAR - March CURRENTSEASONYEAR
    previousSeasonMonthAndYearRange: (_, getters) => {
      return `April ${getters.currentSeasonYear - 1} - March ${getters.currentSeasonYear}`;
    },
    earliestMonthsCanPlan: (state) => state.earliestMonthsCanPlan,
    latestMonthsCanPlan: (state) => state.latestMonthsCanPlan,
    earliestMonthCanPlanGlobal: (state) => {
      let earliestMonth = 0;
      if (state.earliestMonthsCanPlan.length > 0) {
        earliestMonth = Math.min(...state.earliestMonthsCanPlan.map((plan) => plan.month));
      }
      return earliestMonth;
    },
  },
  mutations: {
    login(state, payload) {
      const name = payload.signInUserSession.idToken.payload.name;
      const email = payload.signInUserSession.idToken.payload.email;
      const customSalesrepId = payload.signInUserSession.idToken.payload["custom:sales_rep_id"];
      const country = payload.signInUserSession.idToken.payload["custom:country"];
      state.user = {
        name: name,
        email: email,
        salesRepId: customSalesrepId,
        country: country,
        admin: ADMIN_SALES_REP_IDS.indexOf(customSalesrepId) > -1,
      };
    },
    setOriginalMaterialAllocation(state, payload) {
      state.originalMaterialAllocation = payload;
    },
    logout(state) {
      state.user = null;
    },
    setTableUpcLoader(state, payload) {
      state.tableUpcLoader = payload;
    },
    setSuggestedData(state, payload) {
      state.suggestedData = payload;
    },
    setFirstDimenionData(state, payload) {
      state.firstDimension = payload;
    },
    setSecondDimenionData(state, payload) {
      state.secondDimension = payload;
    },
    setPlannedMaterialData(state, payload) {
      state.plannedMaterialData = { ...payload };
    },
    setSelectedAllocationMethod(state, payload) {
      state.selectedAllocationMethod = payload;
    },
    setActuals(state, payload) {
      state.actuals = payload;
    },
    setCatalogId(state, payload) {
      state.catalogId = payload;
    },
    setInventory(state, payload) {
      state.inventory = payload;
    },
    setOpenOrders(state, payload) {
      state.openOrders = payload;
    },
    setStockUnits(state, payload) {
      state.stockUnits = payload;
    },
    setHistoricGrowth(state, payload) {
      state.historicGrowth = payload;
    },
    setPreviousSeasonsTotal(state, payload) {
      state.previousSeasonsTotal = payload;
    },
    setSuggestedBooking: (state, payload) => {
      let entry = find(state.suggestedBookings, { month: payload.month, segment: payload.segment, type: payload.type });
      if (!entry) {
        state.suggestedBookings.push(payload);
      } else {
        if (isNaN(payload.gross)) payload.gross = 0;
        if (payload.gross !== null) entry.gross = parseFloat(payload.gross);
        if (payload.units !== null) entry.units = parseInt(payload.units);
      }
    },
    resetStores: (state) => {
      state.previousSeasonsData = cloneDeep(PREVIOUS_SEASONS_DATA);
      state.suggestedBookings = [];
    },
    resetSuggestedBooking: (state) => {
      state.suggestedBookings = [];
    },
    resetSuggestedData: (state) => {
      state.suggestedData = [];
    },
    setMaterialData: (state, payload) => {
      state.materialData = payload;
    },
    updateMaterialDataTotals: (state, payload) => {
      const currentMaterialData = state.materialData;
      const indexOfPayload = currentMaterialData.findIndex((item) => item.material === payload.material);
      currentMaterialData[indexOfPayload] = {
        ...currentMaterialData[indexOfPayload],
        total_units: payload.total_units,
      };
      state.materialData = currentMaterialData;
    },
    setSegmentLevelData: (state, payload) => {
      state.segmentLevelData = payload;
    },
    setWasLastSaveFromSaveButton: (state, payload) => {
      state.wasLastSaveFromSaveButton = payload;
    },
    setUserChangedFlag: (state, payload) => {
      state.userChangedFlag = payload;
    },
    setCustomSavedAllocationValues: (state, payload) => {
      state.customSavedAllocationValues = payload;
    },
    updateCustomSavedAllocationProperty: (state, { key, data }) => {
      state.customSavedAllocationValues[key] = data;
    },
    addCustomSavedAllocationProperty: (state, payload) => {
      state.customSavedAllocationValues[payload] = [];
    },
    pushCustomSavedAllocationProperty: (state, { key, data }) => {
      state.customSavedAllocationValues[key].push(data);
    },
    setCalculatedAllocationValues: (state, payload) => {
      state.calculatedAllocationValues = payload;
    },
    updateCalculatedSavedAllocationProperty: (state, { key, data }) => {
      state.calculatedAllocationValues[key] = [];
      state.calculatedAllocationValues[key].push(data);
    },
    setUnsavedChangesFlag: (state, payload) => {
      state.unsavedChangesFlag = payload;
    },
    setProducts: (state, payload) => {
      state.products = payload;
    },
    updateDiscardedDecimals(state, payload) {
      if (state.discardedDecimals[payload.key] == null) {
        Vue.set(state.discardedDecimals, payload.key, [payload.value]);
      }
    },
    setStockUnitsAsOf: (state, payload) => {
      state.stockUnitsAsOf = payload;
    },
  },
  actions: {
    login({ commit }, payload) {
      commit("login", payload);
    },
    logout({ commit }) {
      commit("logout");
    },
    setCatalogId({ commit }, payload) {
      commit("setCatalogId", payload);
    },
    saveOriginalMaterialAllocation({ commit }, payload) {
      commit("setOriginalMaterialAllocation", payload);
    },
    setTableUpcLoader({ commit }, payload) {
      commit("setTableUpcLoader", payload);
    },
    setSuggestedData({ commit }, payload) {
      commit("setSuggestedData", payload);
    },
    setFirstDimenionData({ commit }, payload) {
      commit("setFirstDimenionData", payload);
    },
    setSecondDimenionData({ commit }, payload) {
      commit("setSecondDimenionData", payload);
    },
    setPlannedMaterialData({ commit }, payload) {
      commit("setPlannedMaterialData", payload);
    },
    setSelectedAllocationMethod({ commit }, payload) {
      commit("setSelectedAllocationMethod", payload);
    },
    setActuals({ commit }, payload) {
      commit("setActuals", payload);
    },
    setInventory({ commit }, payload) {
      commit("setInventory", payload);
    },
    setOpenOrders({ commit }, payload) {
      commit("setOpenOrders", payload);
    },
    setHistoricGrowth({ commit }, payload) {
      commit("setHistoricGrowth", payload);
    },
    setPreviousSeasonsTotal({ commit }, payload) {
      commit("setPreviousSeasonsTotal", payload);
    },
    setSuggestedBooking: ({ commit }, payload) => {
      commit("setSuggestedBooking", payload);
    },
    resetStores: ({ commit }) => {
      commit("resetStores");
    },
    resetSuggestedBooking: ({ commit }) => {
      commit("resetSuggestedBooking");
    },
    resetSuggestedData: ({ commit }) => {
      commit("resetSuggestedData");
    },
    setMaterialData: ({ commit }, payload) => {
      commit("setMaterialData", payload);
    },
    setSegmentLevelData: ({ commit }, payload) => {
      commit("setSegmentLevelData", payload);
    },
    updateMaterialDataTotals: ({ commit }, payload) => {
      commit("updateMaterialDataTotals", payload);
    },
  },
});

export default store;
