<template>
    <div class="tooltip-container" v-tooltip.right="text">
        <Icon icon="info-hover" />
    </div>
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
    components: {
        Icon,
    },
    props: {
        text: {
            type: String,
            default: 'Tooltip text.',
        },
    },
};
</script>

<style>
.tooltip-container {
    display: inline-block;
}

.gg-info {
    opacity: 0.5;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    transform: scale(var(--ggs, 1));
    width: 20px;
    height: 20px;
    border: 2px solid;
    border-radius: 40px;
    margin-bottom: -3px;
    margin-left: 0.5rem;
}

.gg-info::after,
.gg-info::before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    border-radius: 3px;
    width: 2px;
    background: currentColor;
    left: 7px;
}

.gg-info::after {
    bottom: 2px;
    height: 8px;
}

.gg-info::before {
    height: 2px;
    top: 2px;
}

.gg-info:hover {
    opacity: 1;
}
</style>
