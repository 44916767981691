import Vue from 'vue';


    const Overlay = Vue.extend(
    {
      data() {
        return {
          visible: false,
          message: '',
        };
      },
      render(h) {
        if (!this.visible) return null;

        return h('div', {
          style: {
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '9999',
          }
        }, [
          h('div', {
            style: {
              padding: '20px',
              color: "rgb(0,154,205)",
              backgroundColor: '#fff',
              borderRadius: '10px',
              border: '2px solid rgb(0,154,205)',
            }
          }, this.message)
        ]);
      }
    });

    const overlayInstance = new Overlay();
    overlayInstance.$mount();
    document.body.appendChild(overlayInstance.$el);

    Vue.prototype.$showOverlay = (message) => {
      overlayInstance.visible = true;
      overlayInstance.message = message;
    };

    Vue.prototype.$hideOverlay = () => {
      overlayInstance.visible = false;
    };


    
    
    export default overlayInstance;