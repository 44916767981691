import Warning from './Warning.vue';

const WarningPlugin = {
  install(Vue) {
    const WarningConstructor = Vue.extend(Warning);
    let warningInstance;

    function showWarning(event, text, upperOffset) {
      if (!warningInstance) {
        warningInstance = new WarningConstructor();
        warningInstance.$mount();
        document.body.appendChild(warningInstance.$el);
      }
 
      const inputRect = event.currentTarget.getBoundingClientRect();   
      warningInstance.text = text;
      warningInstance.visible = true;

      const position = {
        left: inputRect.left +  window.scrollX + inputRect.width/2 -100 ,
        top: inputRect.top + window.scrollY -  (inputRect.height + upperOffset )// Adjust as needed
      };

      warningInstance.position = position;
    }

    function hideWarning() {
      if (warningInstance) {
        warningInstance.visible = false;
      }
    }

    Vue.prototype.$warning = {
      show: showWarning, 
      hide: hideWarning
    };
  }
};

export default WarningPlugin;
