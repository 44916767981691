const state = {
    segments: ['Launch', 'Migrating'],
    segments_all: ['Total', 'Launch Catalog', 'Launch SMU', 'Migrating'],
    segments_final_order: ['Total'],
    level1_bth_or_holiday:[ {id: 'bth', name: 'Back To Hockey'}, {id:'holiday', name:'Holiday'}],
    level2_main_description: 
                            [
                                {id: 'ice_hockey_equipment', name: 'Ice Hockey Equipment'}, 
                                {id: 'goal_equipment', name: 'Goal Equipment'}, 
                                {id: 'roller_and_street', name: 'Roller & Street'}, 
                                {id: 'apparel', name: 'Apparel'}, 
                                {id: 'bags', name: 'Bags'}, 
                                {id: 'lifestyle_skates', name: 'Lifestyle Skates'}, 
                                {id: 'hockey_training', name: 'Hockey Training'},
                                {id: 'prosharp', name: 'Prosharp'},
                            ],
    top_planning_inputs: [
                            {id:'segment', name: 'Segment'}, 
                            {id:'pgs', name: 'PGS'}, 
                            {id:'blades', name: 'Blades'},
                            {id:'spare_parts', name: 'Spare Parts'},
                        ], 
    // products: [

    //         {id: 'holiday_roller_and_street_skates',name: 'Skates',display_name: 'Skates',value: 'holiday_roller_and_street_skates',main_description: 'Roller Hockey',group_description: 'Skates',category_value: 'holiday_roller_and_street_skates',parent_category: 'holiday_roller_&_street',level1_bth_or_holiday_id: 'holiday',level2_main_description_id: 'roller_and_street',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['vapor'],elite: ['vapor'], performance: ['vapor'], isActive: false,}},segments: ['Elite', 'Performance'],help: ""},
    //         {id: 'bth_roller_and_street_skates',name: 'Skates',display_name: 'Skates',value: 'bth_roller_and_street_skates',main_description: 'Roller Hockey',group_description: 'Skates',category_value: 'bth_roller_and_street_skates',parent_category: 'bth_roller_&_street',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'roller_and_street',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['bauer', 'mission'],elite: ['mission'], performance: ['mission'], recreational: ['bauer'], isActive: false,}},segments: ['Elite', 'Performance', 'Recreational'],help: ""},
    //         {id: 'bth_ice_hockey_equipment_skates',name: 'Skates',display_name: 'Skates',value: 'bth_ice_hockey_equipment_skates',main_description: 'Ice Hockey',group_description: 'Skates',category_value: 'bth_ice_hockey_equipment_skates',parent_category: 'bth_ice_hockey_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'ice_hockey_equipment',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer', 'vapor'],elite: ['vapor'], performance: ['vapor'], recreational: ['bauer'], isActive: false,}, migrating: {families: ['bauer', 'supreme'],elite: ['supreme'], performance: ['supreme'], recreational: ['bauer'], isActive: false,}},segments: ['Elite', 'Performance', 'Recreational'],help: ""},
    //         {id: 'bth_goal_equipment_skates',name: 'Skates',display_name: 'Skates',value: 'bth_goal_equipment_skates',main_description: 'Ice Hockey Goal',group_description: 'Skates',category_value: 'bth_goal_equipment_skates',parent_category: 'bth_goal_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'goal_equipment',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer'],performance: ['bauer'], isActive: false,}, migrating: {families: ['bauer'],elite: ['bauer'], performance: ['bauer'], recreational: ['bauer'], isActive: false,}},segments: ['Elite', 'Performance', 'Recreational'],help: ""},
    //         {id: 'holiday_lifestyle_skates_skates',name: 'Skates',display_name: 'Skates',value: 'holiday_lifestyle_skates_skates',main_description: 'Rec Skating',group_description: 'Skates',category_value: 'holiday_lifestyle_skates_skates',parent_category: 'holiday_lifestyle_skates',level1_bth_or_holiday_id: 'holiday',level2_main_description_id: 'lifestyle_skates',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['bauer'],ice_skates: ['bauer'], isActive: false,}},segments: ['Ice Skates'],help: ""},
    //         {id: 'bth_lifestyle_skates_skates',name: 'Skates',display_name: 'Skates',value: 'bth_lifestyle_skates_skates',main_description: 'Rec Skating',group_description: 'Skates',category_value: 'bth_lifestyle_skates_skates',parent_category: 'bth_lifestyle_skates',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'lifestyle_skates',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer'],ice_skates: ['bauer'], isActive: false,}},segments: ['Ice Skates'],help: ""},
    //         {id: 'holiday_goal_equipment_sticks',name: 'Sticks',display_name: 'Sticks',value: 'holiday_goal_equipment_sticks',main_description: 'Ice Hockey Goal',group_description: 'Sticks',category_value: 'holiday_goal_equipment_sticks',parent_category: 'holiday_goal_equipment',level1_bth_or_holiday_id: 'holiday',level2_main_description_id: 'goal_equipment',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer', 'vapor'],elite: ['bauer', 'vapor'], performance: ['vapor'], isActive: false,}},segments: ['Elite', 'Performance'],help: ""},
    //         {id: 'holiday_ice_hockey_equipment_sticks',name: 'Sticks',display_name: 'Sticks',value: 'holiday_ice_hockey_equipment_sticks',main_description: 'Ice Hockey',group_description: 'Sticks',category_value: 'holiday_ice_hockey_equipment_sticks',parent_category: 'holiday_ice_hockey_equipment',level1_bth_or_holiday_id: 'holiday',level2_main_description_id: 'ice_hockey_equipment',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer'],elite: ['bauer'], isActive: false,}},segments: ['Elite'],help: ""},
    //         {id: 'bth_roller_and_street_sticks',name: 'Sticks',display_name: 'Sticks',value: 'bth_roller_and_street_sticks',main_description: 'Street Hockey',group_description: 'Sticks',category_value: 'bth_roller_and_street_sticks',parent_category: 'bth_roller_&_street',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'roller_and_street',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['street'],performance: ['street'], recreational: ['street'], isActive: false,}},segments: ['Performance', 'Recreational'],help: ""},
    //         {id: 'bth_ice_hockey_equipment_sticks',name: 'Sticks',display_name: 'Sticks',value: 'bth_ice_hockey_equipment_sticks',main_description: 'Ice Hockey',group_description: 'Sticks',category_value: 'bth_ice_hockey_equipment_sticks',parent_category: 'bth_ice_hockey_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'ice_hockey_equipment',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer', 'vapor'],elite: ['vapor'], performance: ['vapor'], recreational: ['bauer', 'vapor'], isActive: false,}, migrating: {families: ['nexus'],elite: ['nexus'], performance: ['nexus'], isActive: false,}},segments: ['Elite', 'Performance', 'Recreational'],help: ""},
    //         {id: 'holiday_hockey_training_tiles',name: 'Tiles',display_name: 'Tiles',value: 'holiday_hockey_training_tiles',main_description: 'Hockey Training',group_description: 'Sport Court Tiles',category_value: 'holiday_hockey_training_tiles',parent_category: 'holiday_hockey_training',level1_bth_or_holiday_id: 'holiday',level2_main_description_id: 'hockey_training',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer'],edging_and_bumpers: ['bauer'], home_tile: ['bauer'], isActive: false,}},segments: ['Edging and Bumpers', 'Home Tile'],help: ""},
    //         {id: 'bth_goal_equipment_sticks',name: 'Sticks',display_name: 'Sticks',value: 'bth_goal_equipment_sticks',main_description: 'Ice Hockey Goal',group_description: 'Sticks',category_value: 'bth_goal_equipment_sticks',parent_category: 'bth_goal_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'goal_equipment',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer', 'vapor'],elite: ['vapor'], performance: ['bauer', 'vapor'], recreational: ['bauer'], isActive: false,}, migrating: {families: ['prodigy', 'supreme'],elite: ['supreme'], performance: ['supreme'], recreational: ['prodigy'], isActive: false,}},segments: ['Elite', 'Performance', 'Recreational'],help: ""},
    //         {id: 'bth_ice_hockey_equipment_helmets',name: 'Helmets',display_name: 'Helmets',value: 'bth_ice_hockey_equipment_helmets',main_description: 'Ice Hockey',group_description: 'Helmets & Combos',category_value: 'bth_ice_hockey_equipment_helmets',parent_category: 'bth_ice_hockey_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'ice_hockey_equipment',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer'],helmets: ['bauer'], helmet_combos: ['bauer'], isActive: false,}, migrating: {families: ['bauer'],helmets: ['bauer'], helmet_combos: ['bauer'], isActive: false,}},segments: ['Helmet Combos', 'Helmets'],help: ""},
    //         {id: 'bth_ice_hockey_equipment_facial_protection',name: 'Facial Protection',display_name: 'Facial Protection',value: 'bth_ice_hockey_equipment_facial_protection',main_description: 'Ice Hockey',group_description: 'Facial Protection',category_value: 'bth_ice_hockey_equipment_facial_protection',parent_category: 'bth_ice_hockey_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'ice_hockey_equipment',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer'],wire_cage: ['bauer'], isActive: false,}, migrating: {families: ['bauer'],full_visor: ['bauer'], half_visor_certified: ['bauer'], wire_cage: ['bauer'], isActive: false,}},segments: ['Full Visor', 'Half Visor Certified', 'Wire Cage'],help: ""},
    //         {id: 'bth_goal_equipment_masks',name: 'Masks',display_name: 'Masks',value: 'bth_goal_equipment_masks',main_description: 'Ice Hockey Goal',group_description: 'Goal Masks',category_value: 'bth_goal_equipment_masks',parent_category: 'bth_goal_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'goal_equipment',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['bauer', 'nme'],goal_masks_certified: ['bauer', 'nme'], goal_masks_non_certified: ['bauer', 'nme'], isActive: false,}},segments: ['Goal Masks Certified', 'Goal Masks Non-Certified'],help: ""},
    //         {id: 'bth_ice_hockey_equipment_gloves',name: 'Gloves',display_name: 'Gloves',value: 'bth_ice_hockey_equipment_gloves',main_description: 'Ice Hockey',group_description: 'Gloves',category_value: 'bth_ice_hockey_equipment_gloves',parent_category: 'bth_ice_hockey_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'ice_hockey_equipment',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['supreme'],elite: ['supreme'], performance: ['supreme'], isActive: false,}, migrating: {families: ['bauer', 'vapor'],elite: ['bauer', 'vapor'], performance: ['vapor'], recreational: ['bauer'], isActive: false,}},segments: ['Elite', 'Performance', 'Recreational'],help: ""},
    //         {id: 'bth_goal_equipment_catchers_blockers',name: 'Catchers/Blockers',display_name: 'Catchers/Blockers',value: 'bth_goal_equipment_catchers_blockers',main_description: 'Ice Hockey Goal',group_description: 'Goal Catchers / Blockers',category_value: 'bth_goal_equipment_catchers_blockers',parent_category: 'bth_goal_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'goal_equipment',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer', 'vapor'],elite: ['vapor'], performance: ['vapor'], recreational: ['bauer'], isActive: false,}, migrating: {families: ['prodigy', 'supreme'],elite: ['supreme'], performance: ['supreme'], recreational: ['prodigy'], isActive: false,}},segments: ['Elite', 'Performance', 'Recreational'],help: ""},
    //         {id: 'bth_goal_equipment_pants',name: 'Pants',display_name: 'Pants',value: 'bth_goal_equipment_pants',main_description: 'Ice Hockey Goal',group_description: 'Pants',category_value: 'bth_goal_equipment_pants',parent_category: 'bth_goal_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'goal_equipment',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer'],goal_pants: ['bauer'], isActive: false,}, migrating: {families: ['bauer', 'prodigy'],goal_pants: ['prodigy'], shell: ['bauer'], isActive: false,}},segments: ['Goal Pants', 'Shell'],help: ""},
    //         {id: 'bth_ice_hockey_equipment_pants',name: 'Pants',display_name: 'Pants',value: 'bth_ice_hockey_equipment_pants',main_description: 'Ice Hockey',group_description: 'Pants',category_value: 'bth_ice_hockey_equipment_pants',parent_category: 'bth_ice_hockey_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'ice_hockey_equipment',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['supreme'],pants: ['supreme'], isActive: false,}, migrating: {families: ['bauer', 'supreme', 'vapor'],girdle: ['supreme'], officials: ['bauer'], pants: ['bauer', 'supreme', 'vapor'], shell: ['bauer'], isActive: false,}},segments: ['Girdle', 'Officials', 'Pants', 'Shell'],help: ""},
    //         {id: 'bth_ice_hockey_equipment_shoulder_pads',name: 'Shoulder Pads',display_name: 'Shoulder Pads',value: 'bth_ice_hockey_equipment_shoulder_pads',main_description: 'Ice Hockey',group_description: 'Under Protective',category_value: 'bth_ice_hockey_equipment_shoulder_pads',parent_category: 'bth_ice_hockey_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'ice_hockey_equipment',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['supreme'],elite: ['supreme'], performance: ['supreme'], isActive: false,}, migrating: {families: ['bauer', 'vapor'],elite: ['bauer', 'vapor'], performance: ['vapor'], recreational: ['bauer'], isActive: false,}},segments: ['Elite', 'Performance', 'Recreational'],help: ""},
    //         {id: 'bth_ice_hockey_equipment_officials',name: 'Officials',display_name: 'Officials',value: 'bth_ice_hockey_equipment_officials',main_description: 'Ice Hockey',group_description: 'Under Protective',category_value: 'bth_ice_hockey_equipment_officials',parent_category: 'bth_ice_hockey_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'ice_hockey_equipment',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['bauer'],performance: ['bauer'], isActive: false,}},segments: ['Performance'],help: ""},
    //         {id: 'bth_ice_hockey_equipment_elbow_pads',name: 'Elbow Pads',display_name: 'Elbow Pads',value: 'bth_ice_hockey_equipment_elbow_pads',main_description: 'Ice Hockey',group_description: 'Under Protective',category_value: 'bth_ice_hockey_equipment_elbow_pads',parent_category: 'bth_ice_hockey_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'ice_hockey_equipment',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['supreme'],elite: ['supreme'], performance: ['supreme'], isActive: false,}, migrating: {families: ['bauer', 'vapor'],elite: ['bauer', 'vapor'], performance: ['vapor'], recreational: ['bauer'], isActive: false,}},segments: ['Elite', 'Performance', 'Recreational'],help: ""},
    //         {id: 'bth_ice_hockey_equipment_shin_guards',name: 'Shin Guards',display_name: 'Shin Guards',value: 'bth_ice_hockey_equipment_shin_guards',main_description: 'Ice Hockey',group_description: 'Under Protective',category_value: 'bth_ice_hockey_equipment_shin_guards',parent_category: 'bth_ice_hockey_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'ice_hockey_equipment',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['supreme'],elite: ['supreme'], performance: ['supreme'], isActive: false,}, migrating: {families: ['bauer', 'vapor'],elite: ['bauer', 'vapor'], performance: ['vapor'], recreational: ['bauer'], isActive: false,}},segments: ['Elite', 'Performance', 'Recreational'],help: ""},
    //         {id: 'bth_goal_equipment_chest_protector',name: 'Chest Protector',display_name: 'Chest Protector',value: 'bth_goal_equipment_chest_protector',main_description: 'Ice Hockey Goal',group_description: 'Chest Protectors',category_value: 'bth_goal_equipment_chest_protector',parent_category: 'bth_goal_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'goal_equipment',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer', 'vapor'],elite: ['vapor'], performance: ['bauer'], recreational: ['bauer'], isActive: false,}, migrating: {families: ['prodigy', 'supreme'],elite: ['supreme'], recreational: ['prodigy'], isActive: false,}},segments: ['Elite', 'Performance', 'Recreational'],help: ""},
    //         {id: 'bth_goal_equipment_leg_pads',name: 'Leg Pads',display_name: 'Leg Pads',value: 'bth_goal_equipment_leg_pads',main_description: 'Ice Hockey Goal',group_description: 'Leg Pads',category_value: 'bth_goal_equipment_leg_pads',parent_category: 'bth_goal_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'goal_equipment',top_planning_inputs_id: 'segment',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer', 'vapor'],elite: ['vapor'], performance: ['vapor'], recreational: ['bauer'], isActive: false,}, migrating: {families: ['prodigy', 'supreme'],elite: ['supreme'], performance: ['supreme'], recreational: ['prodigy'], isActive: false,}},segments: ['Elite', 'Performance', 'Recreational'],help: ""},
    //         {id: 'bth_roller_and_street_player_protective_and_helmets',name: 'Player Protective & Helmets',display_name: 'Player Protective & Helmets',value: 'bth_roller_and_street_player_protective_and_helmets',main_description: 'Street Hockey',group_description: 'Street Hockey Protective',category_value: 'bth_roller_and_street_player_protective_and_helmets',parent_category: 'bth_roller_&_street',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'roller_and_street',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['street'],elbow_pads: ['street'], gloves: ['street'], shin_guards: ['street'], isActive: false,}},segments: ['Elbow Pads', 'Gloves', 'Shin Guards'],help: ""},
    //         {id: 'holiday_hockey_training_training_aids',name: 'Training Aids',display_name: 'Training Aids',value: 'holiday_hockey_training_training_aids',main_description: 'Hockey Training',group_description: 'Stick Handling',category_value: 'holiday_hockey_training_training_aids',parent_category: 'holiday_hockey_training',level1_bth_or_holiday_id: 'holiday',level2_main_description_id: 'hockey_training',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer'],electronics: ['bauer'], rebounder: ['bauer'], stick_handling: ['bauer'], training_kits: ['bauer'], isActive: false,}},segments: ['Electronics', 'Rebounder', 'Stick Handling', 'Training Kits'],help: ""},
    //         {id: 'bth_ice_hockey_equipment_accessories',name: 'Accessories',display_name: 'Accessories',value: 'bth_ice_hockey_equipment_accessories',main_description: 'Ice Hockey',group_description: 'Accessories',category_value: 'bth_ice_hockey_equipment_accessories',parent_category: 'bth_ice_hockey_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'ice_hockey_equipment',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer', 'nexus', 'vapor'],accessories: ['bauer'], end_plug: ['nexus', 'vapor'], pucks: ['bauer'], skate_accessories: ['bauer'], isActive: false,}, migrating: {families: ['bauer', 'core', 'supreme'],accessories: ['bauer'], accessory_bags: ['core'], end_plug: ['supreme'], footbeds: ['bauer'], officials: ['bauer'], pucks: ['bauer'], skate_accessories: ['bauer'], spare_parts: ['bauer'], isActive: false,}},segments: ['Accessories', 'Accessory Bags', 'End Plug', 'Footbeds', 'Officials', 'Pucks', 'Skate Accessories', 'Spare Parts'],help: ""},
    //         {id: 'holiday_ice_hockey_equipment_accessories',name: 'Accessories',display_name: 'Accessories',value: 'holiday_ice_hockey_equipment_accessories',main_description: 'Ice Hockey',group_description: 'Sticks',category_value: 'holiday_ice_hockey_equipment_accessories',parent_category: 'holiday_ice_hockey_equipment',level1_bth_or_holiday_id: 'holiday',level2_main_description_id: 'ice_hockey_equipment',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer'],end_plug: ['bauer'], isActive: false,}, migrating: {families: ['bauer', 'vapor'],accessories: ['bauer'], end_plug: ['vapor'], mini_stick: ['bauer'], skate_accessories: ['bauer'], isActive: false,}},segments: ['Accessories', 'End Plug', 'Mini Stick', 'Skate Accessories'],help: ""},
    //         {id: 'bth_ice_hockey_equipment_blades_and_holders',name: 'Blades & Holders',display_name: 'Blades & Holders',value: 'bth_ice_hockey_equipment_blades_and_holders',main_description: 'Ice Hockey',group_description: 'Accessories',category_value: 'bth_ice_hockey_equipment_blades_and_holders',parent_category: 'bth_ice_hockey_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'ice_hockey_equipment',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer'],blades: ['bauer'], holders: ['bauer'], isActive: false,}, migrating: {families: ['tuuk'],blades: ['tuuk'], holders: ['tuuk'], isActive: false,}},segments: ['Blades', 'Holders'],help: ""},
    //         {id: 'bth_goal_equipment_accessories',name: 'Accessories',display_name: 'Accessories',value: 'bth_goal_equipment_accessories',main_description: 'Ice Hockey Goal',group_description: 'Accessories',category_value: 'bth_goal_equipment_accessories',parent_category: 'bth_goal_equipment',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'goal_equipment',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer', 'vapor'],skate_accessories: ['bauer'], spare_parts: ['vapor'], isActive: false,}, migrating: {families: ['bauer', 'core', 'nme', 'supreme', 'vapor'],accessories: ['bauer'], accessory_bags: ['bauer'], blades: ['bauer'], holders: ['supreme'], mini_stick: ['vapor'], protective_accessories: ['bauer'], protective_ls_top: ['core'], protective_neck_guards: ['bauer'], protective_pants: ['core'], skate_accessories: ['bauer'], spare_parts: ['bauer', 'nme', 'supreme', 'vapor'], wire_cage: ['bauer', 'nme'], isActive: false,}},segments: ['Accessories', 'Accessory Bags', 'Blades', 'Holders', 'Mini Stick', 'Protective Accessories', 'Protective LS Top', 'Protective Neck Guards', 'Protective Pants', 'Skate Accessories', 'Spare Parts', 'Wire Cage'],help: ""},
    //         {id: 'bth_roller_and_street_apparel',name: 'Apparel',display_name: 'Apparel',value: 'bth_roller_and_street_apparel',main_description: 'Roller Hockey',group_description: 'Roller Protective',category_value: 'bth_roller_and_street_apparel',parent_category: 'bth_roller_&_street',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'roller_and_street',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['mission'],protective_s_s_top: ['mission'], protective_shorts: ['mission'], isActive: false,}},segments: ['Protective Shorts', 'Protective SS Top'],help: ""},
    //         {id: 'bth_roller_and_street_goalie_protective_and_masks',name: 'Goalie Protective & Masks',display_name: 'Goalie Protective & Masks',value: 'bth_roller_and_street_goalie_protective_and_masks',main_description: 'Street Hockey',group_description: 'Street Hockey Goalie',category_value: 'bth_roller_and_street_goalie_protective_and_masks',parent_category: 'bth_roller_&_street',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'roller_and_street',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['street'],goalie_sets: ['street'], goal_blockers: ['street'], goal_body_pads: ['street'], goal_catchers: ['street'], goal_masks_non_certified: ['street'], goal_pads: ['street'], isActive: false,}},segments: ['Goal Blockers', 'Goal Body Pads', 'Goal Catchers', 'Goal Masks Non-Certified', 'Goal Pads', 'Goalie Sets'],help: ""},
    //         {id: 'holiday_hockey_training_goals',name: 'Goals',display_name: 'Goals',value: 'holiday_hockey_training_goals',main_description: 'Hockey Training',group_description: 'Goals',category_value: 'holiday_hockey_training_goals',parent_category: 'holiday_hockey_training',level1_bth_or_holiday_id: 'holiday',level2_main_description_id: 'hockey_training',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer'],steel_goals: ['bauer'], isActive: false,}},segments: ['Steel Goals'],help: ""},
    //         {id: 'holiday_roller_and_street_accessories',name: 'Accessories',display_name: 'Accessories',value: 'holiday_roller_and_street_accessories',main_description: 'Street Hockey',group_description: 'Roller Protective',category_value: 'holiday_roller_and_street_accessories',parent_category: 'holiday_roller_&_street',level1_bth_or_holiday_id: 'holiday',level2_main_description_id: 'roller_and_street',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['street'],pucks: ['street'], isActive: false,}},segments: ['Pucks'],help: ""},
    //         {id: 'bth_roller_and_street_accessories',name: 'Accessories',display_name: 'Accessories',value: 'bth_roller_and_street_accessories',main_description: 'Street Hockey',group_description: 'Roller Protective',category_value: 'bth_roller_and_street_accessories',parent_category: 'bth_roller_&_street',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'roller_and_street',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['mission', 'street'],balls: ['street'], mini_stick: ['street'], plastic_goals: ['street'], pucks: ['street'], skate_accessories: ['mission'], steel_goals: ['street'], wheels: ['mission'], isActive: false,}},segments: ['Balls', 'Mini Stick', 'Plastic Goals', 'Pucks', 'Skate Accessories', 'Steel Goals', 'Wheels'],help: ""},
    //         {id: 'bth_apparel_team',name: 'Team',display_name: 'Team',value: 'bth_apparel_team',main_description: 'Hockey Apparel',group_description: 'Team Apparel',category_value: 'bth_apparel_team',parent_category: 'bth_apparel',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'apparel',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['core'],bottoms: ['core'], fleece: ['core'], outerwear_jackets: ['core'], isActive: false,}, migrating: {families: ['core'],bottoms: ['core'], fleece: ['core'], headwear: ['core'], long_sleeve_tops: ['core'], outerwear_jackets: ['core'], outerwear_pants: ['core'], shorts: ['core'], short_sleeve_t_shirts: ['core'], isActive: false,}},segments: ['Bottoms', 'Fleece', 'Headwear', 'Long Sleeve Tops', 'Outerwear Jackets', 'Outerwear Pants', 'Short Sleeve T-Shirts', 'Shorts'],help: ""},
    //         {id: 'holiday_apparel_team',name: 'Team',display_name: 'Team',value: 'holiday_apparel_team',main_description: 'Hockey Apparel',group_description: 'Team Apparel',category_value: 'holiday_apparel_team',parent_category: 'holiday_apparel',level1_bth_or_holiday_id: 'holiday',level2_main_description_id: 'apparel',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['seasonal'],outerwear_jackets: ['seasonal'], isActive: false,}, migrating: {families: ['core'],outerwear_jackets: ['core'], isActive: false,}},segments: ['Outerwear Jackets'],help: ""},
    //         {id: 'bth_apparel_perf_base_layer',name: 'Perf Base Layer',display_name: 'Perf Base Layer',value: 'bth_apparel_perf_base_layer',main_description: 'Hockey Apparel',group_description: 'Performance Base Layer',category_value: 'bth_apparel_perf_base_layer',parent_category: 'bth_apparel',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'apparel',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['core'],accessories: ['core'], isActive: false,}, migrating: {families: ['core'],bottoms: ['core'], headwear: ['core'], long_sleeve_tops: ['core'], shorts: ['core'], isActive: false,}},segments: ['Accessories', 'Bottoms', 'Headwear', 'Long Sleeve Tops', 'Shorts'],help: ""},
    //         {id: 'bth_apparel_perf_protective',name: 'Perf Protective',display_name: 'Perf Protective',value: 'bth_apparel_perf_protective',main_description: 'Hockey Apparel',group_description: 'Performance Protective',category_value: 'bth_apparel_perf_protective',parent_category: 'bth_apparel',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'apparel',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['core'],accessories: ['core'], protective_accessories: ['core'], protective_ls_top: ['core'], protective_neck_guards: ['core'], protective_pants: ['core'], protective_shorts: ['core'], isActive: false,}},segments: ['Accessories', 'Protective Accessories', 'Protective LS Top', 'Protective Neck Guards', 'Protective Pants', 'Protective Shorts'],help: ""},
    //         {id: 'holiday_apparel_perf_socks',name: 'Perf Socks',display_name: 'Perf Socks',value: 'holiday_apparel_perf_socks',main_description: 'Hockey Apparel',group_description: 'Performance Socks',category_value: 'holiday_apparel_perf_socks',parent_category: 'holiday_apparel',level1_bth_or_holiday_id: 'holiday',level2_main_description_id: 'apparel',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['core'],socks: ['core'], isActive: false,}},segments: ['Socks'],help: ""},
    //         {id: 'bth_apparel_perf_socks',name: 'Perf Socks',display_name: 'Perf Socks',value: 'bth_apparel_perf_socks',main_description: 'Hockey Apparel',group_description: 'Performance Socks',category_value: 'bth_apparel_perf_socks',parent_category: 'bth_apparel',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'apparel',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['core'],socks: ['core'], isActive: false,}},segments: ['Socks'],help: ""},
    //         {id: 'bth_apparel_lifestyle',name: 'Lifestyle',display_name: 'Lifestyle',value: 'bth_apparel_lifestyle',main_description: 'Hockey Apparel',group_description: 'LifeStyle Apparel',category_value: 'bth_apparel_lifestyle',parent_category: 'bth_apparel',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'apparel',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['core', 'seasonal'],bottoms: ['seasonal'], fleece: ['seasonal'], footwear: ['core'], headwear: ['seasonal'], long_sleeve_tops: ['seasonal'], outerwear_jackets: ['seasonal'], shorts: ['seasonal'], short_sleeve_t_shirts: ['seasonal'], isActive: false,}, migrating: {families: ['core'],fleece: ['core'], footwear: ['core'], headwear: ['core'], short_sleeve_t_shirts: ['core'], isActive: false,}},segments: ['Bottoms', 'Fleece', 'Footwear', 'Headwear', 'Long Sleeve Tops', 'Outerwear Jackets', 'Short Sleeve T-Shirts', 'Shorts'],help: ""},
    //         {id: 'holiday_apparel_lifestyle',name: 'Lifestyle',display_name: 'Lifestyle',value: 'holiday_apparel_lifestyle',main_description: 'Hockey Apparel',group_description: 'LifeStyle Apparel',category_value: 'holiday_apparel_lifestyle',parent_category: 'holiday_apparel',level1_bth_or_holiday_id: 'holiday',level2_main_description_id: 'apparel',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['seasonal'],bottoms: ['seasonal'], fleece: ['seasonal'], headwear: ['seasonal'], long_sleeve_tops: ['seasonal'], outerwear_jackets: ['seasonal'], short_sleeve_t_shirts: ['seasonal'], isActive: false,}, migrating: {families: ['core', 'seasonal'],accessories: ['seasonal'], footwear: ['core'], outerwear_jackets: ['seasonal'], isActive: false,}},segments: ['Accessories', 'Bottoms', 'Fleece', 'Footwear', 'Headwear', 'Long Sleeve Tops', 'Outerwear Jackets', 'Short Sleeve T-Shirts'],help: ""},
    //         {id: 'bth_apparel_uniforms',name: 'Uniforms',display_name: 'Uniforms',value: 'bth_apparel_uniforms',main_description: 'Hockey Apparel',group_description: 'Uniforms',category_value: 'bth_apparel_uniforms',parent_category: 'bth_apparel',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'apparel',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['core'],practice_jerseys: ['core'], team_hockey_socks: ['core'], isActive: false,}},segments: ['Practice Jerseys', 'Team Hockey Socks'],help: ""},
    //         {id: 'holiday_bags_hockey',name: 'Hockey',display_name: 'Hockey',value: 'holiday_bags_hockey',main_description: 'Hockey Bags',group_description: 'Hockey',category_value: 'holiday_bags_hockey',parent_category: 'holiday_bags',level1_bth_or_holiday_id: 'holiday',level2_main_description_id: 'bags',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {migrating: {families: ['bauer', 'core'],accessory_bags: ['bauer'], backpack___duffle: ['core'], officials: ['bauer'], isActive: false,}},segments: ['Accessory Bags', 'Backpack  Duffle', 'Officials'],help: ""},
    //         {id: 'bth_bags_hockey',name: 'Hockey',display_name: 'Hockey',value: 'bth_bags_hockey',main_description: 'Hockey Bags',group_description: 'Hockey',category_value: 'bth_bags_hockey',parent_category: 'bth_bags',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'bags',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['bauer', 'core'],backpack___duffle: ['bauer', 'core'], carry_bags: ['core'], isActive: false,}, migrating: {families: ['bauer', 'core'],accessory_bags: ['bauer'], backpack___duffle: ['bauer', 'core'], carry_bags: ['bauer', 'core'], wheel_bags: ['bauer'], isActive: false,}},segments: ['Accessory Bags', 'Backpack  Duffle', 'Carry Bags', 'Wheel Bags'],help: ""},
    //         {id: 'bth_bags_goalie',name: 'Goalie',display_name: 'Goalie',value: 'bth_bags_goalie',main_description: 'Hockey Bags',group_description: 'Goalie',category_value: 'bth_bags_goalie',parent_category: 'bth_bags',level1_bth_or_holiday_id: 'bth',level2_main_description_id: 'bags',top_planning_inputs_id: 'pgs',img: '',deadlines: ['2022-07-18', '2022-08-15', '2022-11-22',	'2022-12-12', '2023-01-17',	'2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15',	'2023-06-12', '2023-07-17',	'2023-08-14'],launch_date: '2023-06-27',families: {launch: {families: ['core'],carry_bags: ['core'], isActive: false,}, migrating: {families: ['bauer'],wheel_bags: ['bauer'], isActive: false,}},segments: ['Carry Bags', 'Wheel Bags'],help: ""},

    //    ],
};

const getters = {
    products: (state) => state.products,
    families: (state) => state.families,
    segments: (state) => state.segments,
    segments_all: (state) => state.segments_all,
    segments_final_order: (state) => state.segments_final_order,
    level1_bth_or_holiday: (state) => state.level1_bth_or_holiday, 
    level2_main_description: (state) => state.level2_main_description,
    top_planning_inputs: (state) => state.top_planning_inputs,
};

const mutations = {};

const actions = {};

export default {
    state,
    getters,
    mutations,
    actions,
};
// let care = {
//     id: 'bth_ice_hockey_equipment_skates',
//     name: 'Skates',
//     display_name: 'Skates',
//     value: 'bth_ice_hockey_equipment_skates',
//     main_description: 'Ice Hockey',
//     group_description: 'Skates',
//     category_value: 'bth_ice_hockey_equipment_skates',
//     parent_category: 'bth_ice_hockey_equipment',
//     level1_bth_or_holiday_id: 'bth',
//     level2_main_description_id: 'ice_hockey_equipment',
//     top_planning_inputs_id: 'segment',
//     img: '',
//     deadlines: ['2022-07-18', '2022-08-15', '2022-11-22', '2022-12-12', '2023-01-17', '2023-02-13', '2023-03-13', '2023-04-17', '2023-05-15', '2023-06-12', '2023-07-17', '2023-08-14'],
//     launch_date: '2023-06-27',
//     families: {
//         launch: {
//             families: ['bauer', 'vapor'],
//             elite: ['vapor'],
//             performance: ['vapor'],
//             recreational: ['bauer'],
//             isActive: false,
//         },
//         migrating: {
//             families: ['bauer', 'supreme'],
//             elite: ['supreme'],
//             performance: ['supreme'],
//             recreational: ['bauer'],
//             isActive: false,
//         }
//     },
//     segments: ['Elite', 'Performance', 'Recreational'],
//     help: ""
// }