import Vue from 'vue';
import Router from 'vue-router';

import Planning from '@/views/Planning';
import Reporting from'@/views/Reporting';

import store from '@/store/store';

Vue.use(Router);

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '',
            component: () => import('@/views/Auth'),
            children: [
                {
                    path: '',
                    component: () => import('@/components/auth/SignIn'),
                    meta: {
                        public: true,
                    },
                },
                {
                    name: 'Reset',
                    path: '/reset',
                    component: () => import('@/components/auth/Reset'),
                    meta: {
                        public: true,
                    },
                },
            ],
        },
        {
            path: '/user',
            component: () => import('@/components/user/User'),
            children: [
                {
                    path: '',
                    component: () => import('@/views/SoldTo'),
                },
                {
                    path: '/products',
                    component: () => import('@/views/Category'),
                },
            ],
        },
        {
            path: '/portal',
            component: () => import('@/components/portal/Portal')
        },
        {
            path: '/planning',
            component: Planning,
            children: [
                {
                    path: 'setup',
                    component: () => import('@/components/planning/Setup'),
                    meta: {
                        hideToolbars: true,
                    }
                },
                {
                    path: 'suggested',
                    component: () => import('@/components/planning/SuggestedOrder'),
                },
                {
                    path: 'edit',
                    component: () => import('@/components/planning/BookingsTool'),
                },
                {
                    path: 'uploadtobb',
                    component: () => import('@/components/planning/UploadToBB'),
                },
            ],
        },
        {
            path: '/reporting',
            component: Reporting,
            children: [
                {
                    path: '',
                    component: () => import('@/components/report/ReportList'),
                },
                {
                    path: ':reportID',
                    component: () => import('@/components/report/QuickSightReport'),
                },
            ],
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scroll({
        top: to,
        left: 0,
        behavior: 'smooth',
    });

    const isLoggedIn = !!store.state.user;

    if (!to.meta.public && !isLoggedIn) {
        return next({
            path: '/'
        });
    } else if (to.meta.public && isLoggedIn) {
        return next('/user');
    } else {
        next();
    }
});

export default router;
