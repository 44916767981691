import Vue from 'vue';
import { chain, find } from 'lodash';

Vue.mixin({
    methods: {
        isEmptyString: (str) => !str || !str.length,
        getSum(data, filterObj, key) {
            return chain(data)
                .filter(filterObj)
                .map(key)
                .sum()
                .value();
        },
        getMonthAfterOffset(monthNumber){
            const monthOffset = 8;
            return (monthNumber+monthOffset)%12;
        },
        getData(data, filterObj, condition) {
            if(condition === 'checkEmpty') return find(data, filterObj) || 0;
            if(condition ==='refupc') return find(data, filterObj) || "error";
            return find(data, filterObj) || { units: 0, gross: 0 };
        },
        getDateInEST(dateString) {
            // Parse the year and month from the date string
            const [year, month] = dateString.split('-').map(Number);
            // Create a UTC date using the parsed year and month
            return  new Date(year, month - 1, 1);

            }


    },
});
