import { monthsObject } from "../../constants";

const getters = {
    booking_Year: () => {
        let currentYear = new Date().getFullYear();
        // If we are in April or later, we are in the next season
        if(new Date().getMonth() > 2) currentYear = currentYear + 1;
        return currentYear;
    },
    planningConfig: (state) => state.planningConfig,
    season: (state) => state.planningConfig?.season,
    retailer: (state) => state.planningConfig?.retailer,
    shipTo: (state) => state.planningConfig?.shipTo,
    product: (state) => state.planningConfig?.product,
    isShipToSelected: (state) => !!state.planningConfig?.shipTo,
    growthMultiplier: (state) => state.growthMultiplier,
    months: (state) => {
        return monthsObject.filter(month => state.planningConfig.season.months.includes(month.value));
    },
    excludedMonths: (state) => {
        return monthsObject.filter(month => !state.planningConfig.season.months.includes(month.value));
    },
    bookingYear: (state) => state.bookingYear,
    // TODO RENAME THIS TO SOMETHING MORE DESCRIPTIVE
    currentSeasonMutation: (state) => {
        return `S${ state.planningConfig?.season.year.toString().slice(-2) }-BTH`;
    },
    monthsIndex: (state) => {
        return state.planningConfig.season.months;
    }
};

const CONFIG = {
    season: {
        label: `S'${ getters.booking_Year().toString().slice(-2) }`,
        year: getters.booking_Year(),
        months: [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
        startDate: `${ getters.booking_Year() }-04-01`,
        endDate: `${ getters.booking_Year() + 1 }-03-31`,
        monthsRange: { min: 1, max: 12 },
    },
    retailer: null,
    shipTo: null,
    product: null,
};

const state = {
    planningConfig: { ...CONFIG },
    growthMultiplier: 1.1,
    bookingYear: getters.booking_Year(),
};



const mutations = {
    setPlanningConfig(state, payload) {
        payload.season.year = getters.booking_Year();

        //this is a fix because we need season year in MaterialAllocation.js
        sessionStorage.setItem('seasonYear', getters.booking_Year());

        // COMPOSE CUSTOM ATTRIBUTES
        payload.retailer.SDC = payload.retailer.buying_group_code === 'SDC' ? '' : '%SDC%';
        payload.retailer.SEC = payload.retailer.buying_group_code === 'SEC' ? '' : '%SEC%';
        payload.retailer.marketQuery = `%${ payload.retailer.market }%`;

        // DETERMINE BOOKING MONTH RANGE
        payload.season.monthsRange = {
            min: Math.min(...payload.season.months),
            max: Math.max(...payload.season.months),
        };

        state.planningConfig = payload;
    },
};

const actions = {
    setPlanningConfig({ commit }, payload) {
        commit('setPlanningConfig', payload);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
