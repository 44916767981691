<template>
    <i :class="`gg-${icon}`"></i>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
i {
    display: inline-block !important;
    color: rgba(black, 0.75);
    margin: -3px;
}

.gg-info-hover {
    opacity: 0.5;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    transform: scale(var(--ggs, 1));
    width: 20px;
    height: 20px;
    border: 2px solid;
    border-radius: 40px;
    margin-bottom: -3px;
    margin-left: 0.5rem;
}

.gg-info-hover::after,
.gg-info-hover::before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    border-radius: 3px;
    width: 2px;
    background: currentColor;
    left: 7px;
}

.gg-info-hover::after {
    bottom: 2px;
    height: 8px;
}

.gg-info-hover::before {
    height: 2px;
    top: 2px;
}

.gg-info-hover:hover {
    opacity: 1;
}

</style>
